import config from "@presto-common/config";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Dimensions,
  Image,
  Keyboard,
  Platform,
  ScrollView,
  View,
  Switch,
  Pressable,
} from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import {
  PrestoTextButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import PasswordInput from "@presto-components/TextInputs/PasswordInput";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import LoginObject from "@presto-services/data_models/LoginObject";
import SessionManager from "@presto-services/features/session/SessionManager";
import * as Linking from "expo-linking";
import I18n from "i18n-js";
import { useFocusEffect } from "@react-navigation/native";
import { useMediaQuery } from "react-responsive";
import asyncify from "../../common/Asyncify";
const AsyncSessionManager = asyncify(SessionManager);
import { useAssets } from "presto-react-components";
import AsyncStorage from "@react-native-community/async-storage";
import { prestoAlert } from "../../common/Alert";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import { OfflineSync } from "@presto-helpers/OfflineSync";
import DBUserManager from "@presto-db-managers/DBUserManager";
import _ from "lodash";
import utils from "../../utils/index";
import PrestoSdk from "../../services/global/PrestoSdk";
import MessagesContext from "@presto-contexts/MessagesContext";

export default function LoginWithPassword() {
  const { merchant_config } = config;
  const { images: Images, svgs } = useAssets();
  const windowHeight = Dimensions.get("window").height;
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();
  const isMobile = utils.isMobile();
  const isWeb = Platform.OS == 'web'
  const { theme } = useContext(ThemeContext);
  const { setUser, user } = useContext(UserContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  useEffect(() => {
    setShown(true);
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);
  const [padding, setPadding] = useState(0);
  const { locale, setLanguage } = useContext(MessagesContext);
  const [language, setScreenLanguage] = useState(locale);
  const scrollRef = useRef();

  const onEmailChange = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  const onPasswordChange = ({ nativeEvent: { text } }) => {
    setPassword(text);
  };

  const toggleNetwork = () => {
    if (locale === "en-US") {
      setLanguage("en-Portugese");
    } else {
      setLanguage("en-US");
    }
  };

  const navigateToTc = () => {
    let url = ""
    if (locale === "en-US") {
      url = config.constants.terms_and_conditions
    } else {
      url = config.constants.terms_and_conditions_pr
    }
    if (Platform.OS == "web") {
      console.log("lsadjkfaijso")
      WebNavigator.emit({
        event: "push",
        params: {
          screenName: "TERMS_AND_CONDITION",
          screenParams: { url: url },
        },
      });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "TERMS_AND_CONDITION",
          screenParams: {
            url: url
          },
        },
      });
    }
  }

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);

  useEffect(() => {
    setScreenLanguage(locale);
  }, [locale]);

  const onLogin = () => {
    Keyboard.dismiss();
    setLoading(true);
    async function login() {
      let lobj = new LoginObject();

      lobj.employee = {
        email: email,
        password: password,
      };

      const [error, response] = await AsyncSessionManager.agentLogin(lobj);

      if (error) {
        alertBox(
          I18n.t("screen_messages.common.sorry_text"),
          I18n.t("error_messages.invalid_cred")
        );
        setLoading(false);
      } else {
        const metaData = new DBMetaDataManager();
        await metaData.setCurrentLoggedUserInfo(response.data);
        setUser(response.data);
        PrestoSdk.setMerchantId(response.data.merchant_id.$oid);
        if (!isWeb) {
          const offlineSync = new OfflineSync(response.data);
          const force = true;
          await offlineSync.syncAll(force);

          const bBUserManager = new DBUserManager();
          const users = await bBUserManager.all();

          if (_.isEmpty(users)) {
            await offlineSync.initialLoadUsers();
          }
        }

        if (isWeb && isDesktop) {
          setLoading(true)
          setTimeout(() => {
            WebNavigator.emit({
              event: "replace",
              params: {
                screenName: "FEATURE_SELECTION",
              },
            });
            setShown(true);
            setLoading(false);
          }, 1000)
        }else{
          setLoading(true);
          setTimeout(() => {
            Navigator.emit({
              event: "replace",
              params: {
                screenName: "FEATURE_SELECTION",
              },
            });
            setShown(true);
            setLoading(false);
          }, 1000);
        }
      }
    }
    login();
  };

  const alertBox = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPressReadMore = () => {
    if (locale === "en-US") {
      Linking.openURL(config.constants.read_more_about_product)
    } else {
      Linking.openURL(config.constants.read_more_about_product_pr)
    }
  };

  const onPressSignUp = () => {
    if (isDesktop) {
      WebNavigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          title: "Sign Up",
          shouldGoBack: true,
          goBack: goBack,
        },
      });
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          screenParams: {
            title: "Sign Up",
            shouldGoBack: true,
            goBack: goBack,
          },
        },
      });
    }
  };

  const onPressSignUpOTP = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "LOGIN_WITH_OTP_SCREEN",
        screenParams: {
          title: "Sign Up",
          shouldGoBack: true,
          goBack: goBack,
        },
      },
    });
  };

  const eyeOnPress = () => {
    setHide(!hide);
  };

  useFocusEffect(
    useCallback(() => {
      if (merchant_config?.features?.get_store_id) {
        async function getStoreId() {
          var get_store_id = await AsyncStorage.getItem("zhc_store_id");
          console.log("Store_id==>", get_store_id);
          if (get_store_id == null) {
            Navigator.emit({
              event: "modal",
              params: {
                screenName: "GET_STORE_ID",
                screenParams: {},
              },
            });
          }
        }
        getStoreId();
      }
    }, [])
  );

  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1 }}
      loading={loading}
    >
      <ScrollView
        ref={scrollRef}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid={true}
        scrollEnabled={true}
        style={{ backgroundColor: theme.primary, flex: 1 }}
        contentContainerStyle={{
          //paddingTop: 100,
          minHeight: windowHeight,
        }}
      >
        {shown ? (
          <View
            style={{
              flex: 1,
              flexDirextion: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: padding,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              <Image
                source={Images.logo_transparent}
                style={{ width: 378, height: 140, alignSelf: "center" }}
              />

              <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                <View style={{ alignItems: "center" }}>
                  <PrestoText size={theme.h5FontSize} color={theme.white}>
                    {I18n.t("screen_messages.login.title")}
                  </PrestoText>
                </View>
                <VerticalSpacing size={15} />
              </FullWidthHorizontalPaddedBox>
              <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                <View style={{ alignItems: "center" }}>
                  <PrestoText size={theme.h5FontSize} color={theme.white}>
                    {I18n.t("screen_messages.login.description")}
                  </PrestoText>
                </View>
                <VerticalSpacing size={15} />
              </FullWidthHorizontalPaddedBox>

              <VerticalSpacing size={15} />
              <View
                style={{ alignSelf: "center",width:!isDesktop? "100%":null}}
              >
                <PrestoTextInput
                  theme={theme}
                  placeholder={I18n.t(
                    "screen_messages.login.your_email_address"
                  )}
                  value={email}
                  onChange={onEmailChange}
                  backgroundColor={theme.white}
                  textContentType={"username"}
                  autoCompleteType={"username"}
                  importantForAutofill={"yes"}
                  style={{
                    textInputHeight: 40,
                    textInputWidth: isDesktop ? (isWeb ? 300 : "50%") : "100%",
                  }}
                />
              </View>
              <VerticalSpacing size={10} />
              <View style={{ alignSelf: "center", height: 40,width:!isDesktop? "100%":null }}>
                <PasswordInput
                  theme={theme}
                  placeholder={I18n.t("screen_messages.login.password")}
                  value={password}
                  onChange={onPasswordChange}
                  backgroundColor={theme.white}
                  textContentType={"password"}
                  autoCompleteType={"password"}
                  importantForAutofill={"yes"}
                  secureTextEntry={hide}
                  icon={Images.eye}
                  onPress={() => eyeOnPress()}
                  style={{
                    textInputHeight: 40,
                    textInputWidth: isDesktop ? (isWeb ? 300 : "50%") : "100%",
                  }}
                />
              </View>
              <VerticalSpacing size={20} />
              <View style={{ alignSelf: "center", width: isWeb && !isMobileWeb ? 300 : "50%" }}>
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="large"
                  title={I18n.t("screen_messages.login.login_btn_name")}
                  borderRadius={20}
                  titleColor={theme.white}
                  borderColor={theme.white}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  onPress={onLogin}
                />
              </View>
              <VerticalSpacing size={20} />
            </FullWidthHorizontalPaddedBox>
            <VerticalSpacing size={20} />
            <View style={{ marginBottom: 40, paddingLeft: 5 }}>
              <PrestoTextButton
                buttonStyle="secondary"
                size="normal"
                title={I18n.t("screen_messages.login.read_more_about")}
                rightIcon={<svgs.RightArrow primaryColor={theme.white} />}
                titleColor={theme.white}
                onPress={() => onPressReadMore()}
              />
            </View>
          </View>
        ) : null}

        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <View>
            <View style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}>
              <PrestoText
                color={theme.white}
                size={16}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.language.portugese")}
              </PrestoText>
              <View style={{ marginHorizontal: 6 }}>
                <Switch
                  trackColor={{
                    false: theme.white,
                    true: theme.white,
                  }}
                  thumbColor={theme.gray}
                  ios_backgroundColor={theme.white}
                  onValueChange={toggleNetwork}
                  value={language === "en-US"}
                />
              </View>
              <PrestoText
                color={theme.white}
                size={16}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.language.english")}
              </PrestoText>
            </View>
            <Pressable
              onPress={navigateToTc}>
              <PrestoText
                color={theme.white}
                size={16}
                fontStyle={"600.semibold"}
                extraStyle={{
                  textDecorationLine: "underline",
                  paddingBottom: 10
                }}
              >
                {I18n.t("screen_messages.privacy_policy_t&c")}
              </PrestoText>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}
