import React, { useContext } from "react";
import { View, FlatList, Pressable } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";
import UserModel from "@presto-component-models/UserModel";
import utils from "../../utils";

export default function CustomersTable({
  items,
  onEndReached,
  activeRow,
  onPressRow,
}) {
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();

  const { theme } = useContext(ThemeContext);
  const tableColumns = [
    {
      title: I18n.t("screen_messages.stock.name"),
      width: "20%",
      thType: "highlighted",
    },
    {
      title: I18n.t("screen_messages.phone_number"),
      width: "20%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.order_count"),
      width: "20%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.orders.last_order_date"),
      width: "20%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.total_bill_amount"),
      width: "20%",
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            ...column.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 13,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ...column.viewStyles,
            };
            textProps = {
              extraStyle: { paddingLeft: 20, textTransform: "capitalize" },
              color: theme.black,
              fontStyle: "400.bold",
              size: 16,
            };
          }

          if (column.title == "Amount") {
            viewStyles = {
              ...viewStyles,
              alignItems: "center",
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText {...textProps}>{column.title}</PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    const user = new UserModel({ user: item });
    let name = user.name;
    let phoneNumber = user.phoneNumber;
    let email = user.email;
    let orderCount = user.statsOrderCount;
    let totalBillAmount = user.statsTotalBillAmount1;
    let lastOrderDate = moment(user.lastOrderDate);

    const isActive = _.get(activeRow, "id") === item.id;
    const bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;

    if (isMobile || isMobileWeb) {
      return (
        <Pressable
          key={`${item.id}-${index}`}
          onPress={() => onPressRow(item)}
          style={{
            width: "100%",
            backgroundColor: bgColor,
            borderRadius: 15,
            paddingHorizontal: theme.primaryPadding,
            paddingVertical: 10,
            flexDirection: "row",
            marginBottom: 5,
          }}
        >
          <View
            style={{
              width: "58%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
            }}
          >
            <PrestoText
              extraStyle={{}}
              color={textColor1}
              fontStyle={"600.semibold"}
            >
              {name}
            </PrestoText>
            {email !== " " ? (
              <PrestoText
                extraStyle={{}}
                color={textColor2}
                fontStyle={"400.normal"}
              >
                {email}
              </PrestoText>
            ) : null}
            <PrestoText color={textColor2} fontStyle={"400.normal"} size={11}>
              {phoneNumber}
            </PrestoText>
            <PrestoText color={textColor2} fontStyle={"400.normal"} size={11}>
              Total Orders: {orderCount}
            </PrestoText>
          </View>
          <View
            style={{
              width: `30%`,
              justifyContent: "space-around",
              paddingLeft: 10,
            }}
          >
            {lastOrderDate !== null ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrestoText
                  color={textColor2}
                  fontStyle={"400.normal"}
                  size={10}
                >
                  {"Last Ordered - "}
                </PrestoText>
                <PrestoText
                  color={textColor2}
                  fontStyle={"400.medium"}
                  size={12}
                >
                  {lastOrderDate.isValid()
                    ? lastOrderDate.format("DD-MM-YYYY")
                    : null}
                </PrestoText>
              </View>
            ) : null}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrestoText color={textColor2} fontStyle={"400.normal"} size={10}>
                {"Total Spend - "}
              </PrestoText>
              <PrestoText color={textColor2} fontStyle={"600.semibold"}>
                {totalBillAmount}
              </PrestoText>
            </View>
          </View>
        </Pressable>
      );
    }
    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 15,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "20%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor1}
            fontStyle={"600.semibold"}
          >
            {name}
          </PrestoText>

          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {email}
          </PrestoText>
        </View>

        <View
          style={{
            width: `20%`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText color={textColor2} fontStyle={"400.normal"} size={11}>
            {phoneNumber}
          </PrestoText>
        </View>

        <View
          style={{
            width: `20%`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText color={textColor2} fontStyle={"400.normal"} size={11}>
            {orderCount}
          </PrestoText>
        </View>

        <View
          style={{
            width: `20%`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText color={textColor2} fontStyle={"400.normal"} size={11}>
            {lastOrderDate.isValid()
              ? lastOrderDate.format("DD-MM-YYYY")
              : null}
          </PrestoText>
        </View>

        <View
          style={{
            width: "20%",
            flexDirection: "row",
            borderLeftWidth: 1,
            borderLeftColor: "#DBDBDB",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: 10,
          }}
        >
          <PrestoText color={textColor2} fontStyle={"600.semibold"}>
            {totalBillAmount}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  return (
    <View>
      <FlatList
        stickyHeaderIndices={[0]}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={items}
        renderItem={renderItem}
        ListHeaderComponent={() => {
          if (isMobile || isMobileWeb) return;
          return <RenderItemHeader />;
        }}
        onEndReached={onEndReached}
      />
    </View>
  );
}
