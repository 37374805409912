import React, { useContext, useReducer, useEffect, useRef } from "react";
import { View, FlatList, Image, Dimensions, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import LoadingContainer from "@presto-components/LoadingContainer";
import Utils from "@presto-services/common/Utils";
import PosManager from "@presto-services/features/pos/PosManager";
import UserModel from "@presto-component-models/UserModel";
import CustomerNameWithPhone from "@presto-cards/CustomerInfo/CustomerNameWithPhone";
import Pressable from "@presto-components/Pressable/Pressable";
import { useAssets } from "presto-react-components";
import { prestoAlert } from "../../common/Alert";
import CartContext from "@presto-contexts/CartContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import HeaderWithBackAndLogoAndRightTitle from "@presto-components/Headers/HeaderWithBackAndLogoAndRightTitle";
import CartDataSources from "@presto-datasources/CartDataSources";
import ClientDataSource from "@presto-datasources/ClientDataSource";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import UserContext from "@presto-contexts/UserContext";
import config from "@presto-common/config";
import utils from "../../utils";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{ query: payload },
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload };

    case "SET_LOADING":
      return { ...state, loading: payload };
    case "SET_SEARCH_HELP_TEXT":
      return { ...state, searchHelpText: payload };
  }
  return state;
}

export default function SearchScreen({ navigation, route }) {
  const { Navigator } = useContext(NavigatorContext);
  const { svgs, images } = useAssets();
  const isWeb = Platform.OS == 'web'
  const searchParams = route?.params?.searchParams;
  const {
    filter,
    allowToProceedAsGuestUser = false,
    showAddCustomerButton = false,
    offlineEnabledAPIForCustomerSearch = false,
    onSaveCustomerInfoCallback,
    onPressItemCallback,
    setParentLoading,
  } = route?.params;
  const { theme } = useContext(ThemeContext);
  const isMobileWeb = utils.isMobileWeb();
  const { user } = useContext(UserContext);
  const { cart, refreshCart, currentCartData } = useContext(CartContext);
  const ref = useRef();
  const [state, dispatch] = useReducer(reducer, {
    query: searchParams?.query,
    search_results: searchParams?.search_results ?? [],
    loading: false,
    searchHelpText: "",
  });
  ref.current = state;
  const windowHeight = Dimensions.get("window").height;
  const searchInputRef = useRef();

  const { loading, searchHelpText } = state;

  const methodGenerator = Utils.generateReducerMethod(dispatch);
  const [setSearchHelpText, setSearchQuery] = _.map(
    ["SET_SEARCH_HELP_TEXT", "SET_SEARCH_QUERY"],
    (stateName) => methodGenerator(stateName)
  );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (state.query?.length >= 3) {
      onSearch(state.query);
    } else {
      dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
    }
  }, [state.query]);

  const init = () => {
    setDefaultSearchText();
  };

  const setDefaultSearchText = () => {
    setSearchHelpText([
      I18n.t("screen_messages.common.start_typing"),
      {
        text: I18n.t("screen_messages.common.name_or_number"),
        extraStyle: {
          fontWeight: "600.semibold",
        },
      },
    ]);
  };

  const alert = (title, message) => {
    prestoAlert(
      title,

      message,

      [
        {
          text: I18n.t("screen_messages.common.button_ok"),

          style: "cancel",
        },
      ],

      false
    );
  };

  const gotoCustomerProfile = (id, merchant_id, text, item) => {
    if (filter != "Returns") {
      if (_.isEmpty(id) && _.isEmpty(state.query)) {
        return alertBox(
          I18n.t("error_messages.search_customers.not_found_title"),
          I18n.t("error_messages.search_customers.not_found_desc")
        );
      }
    }

    if (id) {
      if (filter == "Returns") {
        if(isMobileWeb){
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "ORDER_LIST",
              screenParams: {
                customer: { ...item },
              },
            },
          });
        }else{
          navigation.navigate("OrdersList", { customer: { ...item } });
        }
      } else {
        let selectedCustomer = item;
        if (_.isFunction(onPressItemCallback)) {
          onPressItemCallback(selectedCustomer);
        } else {
          if(isMobileWeb){
             Navigator.emit({
               event: "modal",
               params: {
                 screenName: "CART_CHECKOUT",
                 screenParams: {
                   customer: selectedCustomer,
                 },
               },
             });
          }else{
            navigation.navigate("CartCheckout", { customer: selectedCustomer });
          }
        }
      }
    } else {
      if (filter == "Returns") {
        return;
      }
      if(isMobileWeb){
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "ADD_CUSTOMER",
            screenParams: {
              title: text,
              screen: searchParams?.screen,
              setParentLoading: setParentLoading,
              onSaveCustomerInfoCallback: (newCustomer) => {
                if (_.isFunction(onSaveCustomerInfoCallback)) {
                  onSaveCustomerInfoCallback(newCustomer);
                }
              },
            },
          },
        });
      }else{
        navigation.navigate("AddCustomer", {
          title: text,
          screen: searchParams?.screen,
          onSaveCustomerInfoCallback: (newCustomer) => {
            if (_.isFunction(onSaveCustomerInfoCallback)) {
              onSaveCustomerInfoCallback(newCustomer);
            }
          },
        });
      }
    }
  };

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={`${item?.email}`}
        style={{
          paddingHorizontal: theme.primaryPadding,
        }}
      >
        <CustomerNameWithPhone
          customer={new UserModel({ user: item })}
          onPress={() =>
            gotoCustomerProfile(item.id, item.merchant_id, state.query, item)
          }
          containerStyles={{
            borderRadius: 12,
            borderBottomWidth: 0,
          }}
        />
        <VerticalSpacing size={15} />
      </View>
    );
  };

  useEffect(() => {
    if (route?.params?.focus) {
      searchInputRef.current?.focus();
    }
  }, []);

  const onSearch = async (text) => {
    setSearchQuery(text);
    if (text && text.length >= 3) {
      dispatch({ type: "SET_LOADING", payload: true });

      let params = {};
      params.parameter = Utils.getSearchType(text);
      params.value = text;
      params.enableOffline = isWeb ? false : offlineEnabledAPIForCustomerSearch;
      ClientDataSource.searchUser(
        params,
        (response) => {
          let users = response?.data?.users;
          users = users
            ? Array.from(new Set(users.map((user) => user.id))).map((id) =>
                users.find((user) => user.id === id)
              )
            : [];
          dispatch({ type: "SET_SEARCH_RESULTS", payload: users });
          dispatch({ type: "SET_LOADING", payload: false });

          if (!users?.length) {
            setSearchHelpText([
              I18n.t("screen_messages.common.customer_does_not_exists"),
            ]);
          } else {
            setSearchHelpText([
              I18n.t("screen_messages.common.select_one"),
              {
                text: text,
                extraStyle: {
                  fontWeight: "600.semibold",
                },
              },
            ]);
          }
        },
        (error) => {
          console.log("search user error = ", error);
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
        }
      );
    } else {
      if (!text) {
        setDefaultSearchText();
      } else {
        setSearchHelpText([
          I18n.t("screen_messages.common.select_one"),
          {
            text: text,
            extraStyle: {
              fontWeight: "600.semibold",
            },
          },
        ]);
      }
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
    }
  };

  const alertBox = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPress = (text) => {
    const customer = _.first(state.search_results);
    return gotoCustomerProfile(
      customer?.id,
      customer?.merchant_id,
      text,
      customer
    );
  };

  const onPressBack = () => {
    navigation.navigate("CartScreen");
  };

  const searchBar = () => {
    return (
      <View
        style={{
          padding: theme.primaryPadding,
        }}
      >
        <PrestoTextInput
          style={{
            textInputHeight: 36,
            borderRadius: 12,
          }}
          theme={theme}
          placeholder={I18n.t("screen_messages.customer.search_customer")}
          value={state.query}
          keyboardType={"number-pad"}
          onChange={({ nativeEvent: { text } }) => onSearch(text)}
          backgroundColor={theme.white}
        />

        <PrestoText
          fontStyle="400.normal"
          size={12}
          color={theme.white}
          extraStyle={{ paddingVertical: 5, paddingLeft: theme.primaryPadding }}
        >
          {_.isString(searchHelpText)
            ? searchHelpText
            : _.isArray(searchHelpText)
              ? _.map(searchHelpText, (item, index) => {
                if (_.isString(item)) {
                  return `${item} `;
                }
                return (
                  <PrestoText
                    key={index}
                    fontStyle="600.semibold"
                    size={12}
                    color={theme.white}
                    extraStyle={item.extraStyle}
                  >
                    {item.text}
                  </PrestoText>
                );
              })
              : ""}
        </PrestoText>
      </View>
    );
  };

  const NoSearchResultComponent = () => {
    return (
      <Pressable
        style={{
          paddingVertical: 15,
          backgroundColor: theme.topHeaderInactiveColor,
        }}
      >
        <View style={{ paddingLeft: 21 }}>
          <PrestoText fontStyle="500.medium" size={18} color={theme.white}>
            {I18n.t("screen_messages.no_details_found")}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const SearchListHeaderSection = () => {
    return (
      <View>
        {loading
          ? renderItem({
            item: {
              name: "Searching...",
            },
            index: 0,
          })
          : null}

        {state.noSearchResults && state.query?.length >= 3 ? (
          <NoSearchResultComponent />
        ) : null}
      </View>
    );
  };

  return (
    <View
      style={{ backgroundColor: theme.topHeaderInactiveColor, height: "100%" }}
    >
      <View
        style={{
          backgroundColor: theme.topHeaderInactiveColor,
        }}
      >
        <View style={{ justifyContent: "space-between" }}>
          {searchBar()}

          <LoadingContainer bgColor="transparent" loading={loading}>
            <FlatList
              data={state.search_results}
              contentContainerStyle={{}}
              renderItem={renderItem}
              keyExtractor={(item) => item.email}
              keyboardShouldPersistTaps="always"
            />
          </LoadingContainer>
        </View>
      </View>
      <LoadingContainer
        bgColor={theme.topHeaderInactiveColor}
        loading={loading}
      >
        <FlatList
          data={state.search_results}
          contentContainerStyle={{ paddingBottom: 10 }}
          renderItem={renderItem}
          keyExtractor={(item) => item.email}
          ListHeaderComponent={<SearchListHeaderSection />}
          stickyHeaderIndices={[0]}
          keyboardShouldPersistTaps="always"
        />

        {allowToProceedAsGuestUser || showAddCustomerButton ? (
          <View
            style={{
              position: "relative",
            }}
          >
            <View
              style={{
                position: "absolute",
                bottom: 30,
                paddingHorizontal: theme.primaryPadding,
                width: "100%",
              }}
            >
              {showAddCustomerButton &&
                !_.isEmpty(state.query) &&
                _.isEmpty(state.search_results) ? (
                <PrestoSolidButton
                  size="large"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.common.create_and_continue")}
                  titleColor={theme.white}
                  titleFontStyle={"600.semibold"}
                  titleSize={20}
                  height={60}
                  borderRadius={12}
                  onPress={() => onPress(state.query)}
                  extraStyle={{
                    marginBottom: theme.primaryPadding,
                  }}
                />
              ) : null}

              {allowToProceedAsGuestUser ? (
                <PrestoSolidButton
                  size="large"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.customers.continue_as_guest")}
                  titleColor={theme.white}
                  titleFontStyle={"600.semibold"}
                  titleSize={20}
                  height={60}
                  borderRadius={12}
                  onPress={() => {
                    refreshCart();
                    const guestUserId = user.other_data.guest_user_id;
                    const guestUser = {
                      id: guestUserId,
                      name: "Guest",
                      phone_number: "",
                      email: "",
                      merchant_id: config.server_config.merchant_id,
                    };
                    gotoCustomerProfile(
                      guestUserId,
                      user.merchant_id,
                      "guest",
                      guestUser
                    );
                  }}
                />
              ) : null}
            </View>
          </View>
        ) : null}
      </LoadingContainer>
    </View>
  );
}
