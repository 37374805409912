import React, { useRef, useContext, useState } from "react";
import { Dimensions, View, Pressable, TouchableOpacity, ScrollView,Platform } from "react-native";
import I18n from "i18n-js";
import moment from "moment";
import _ from "lodash";
import { Components } from "presto-react-components";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useActionSheet } from "@expo/react-native-action-sheet";
import YearMonthPicker from "@presto-components/yearMonthPicker";
import YearMonthSelect from "../../components/yearMonthSelect";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import DefaultModal from "@presto-components/Modals/Modal.bookmychef";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import utils from "../../utils";

const DatePicker = Components.DatePicker;

export default function NonZipPurchases(props) {
  const {
    item,
    reason = "",
    reasons,
    goBack,
    buttonTitle = "",
    onDateChange,
    showDropDown,
    incomingArray = [],
    setExpiryDate,
    onPressButton,
    onPressDropDown,
    onPurchaseTaxChange,
    onBatchNumberChange,
    onPurchasePriceChange,
    onPurchaseQuantityChange,
  } = props?.route?.params;
  const modalRef = useRef();
  let keyBoardType = "numeric";
  const [open, setOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { showActionSheetWithOptions } = useActionSheet();
  const [selectedReason, setSelectedReason] = useState(reason);
  const [selected, setSelected] = useState("");
  const windowHeight = Dimensions.get("window").height;
  const isWeb = Platform.OS == "web";
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();

  let array = !_.isEmpty(incomingArray)
    ? incomingArray
    : [
      {
        value: item.batch_number?.toString(),
        function: onBatchNumberChange,
        title: I18n.t("screen_messages.non_zip_purchases.batch_invoice_no"),
      },
      {
        keyboardType: keyBoardType,
        function: onPurchasePriceChange,
        value: item.purchase_price_value?.toString(),
        title: I18n.t("screen_messages.non_zip_purchases.product_cost"),
      },
      {
        keyboardType: keyBoardType,
        function: onPurchaseTaxChange,
        value: item.purchase_tax_value?.toString(),
        title: I18n.t("screen_messages.non_zip_purchases.product_tax"),
      },
      {
        keyboardType: keyBoardType,
        value: item.purchase_quantity?.toString(),
        function: onPurchaseQuantityChange,
        title: I18n.t("screen_messages.non_zip_purchases.qty_received"),
      },
    ];

  const HorizontalView = ({ title, children, border }) => {
    return (
      <View
        style={{
          paddingVertical: 20,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <PrestoText extraStyle={{ width: "35%" }}>{title}</PrestoText>
        <View
          style={{
            width: "65%",
            alignItems: "center",
            borderColor: theme.extraGrey,
            borderLeftWidth: border ? 1 : 0,
            borderRightWidth: border ? 1 : 0,
          }}
        >
          {children}
        </View>
      </View>
    );
  };

  const InputWithText = ({ i }) => {
    const [value, setValue] = useState(i.value);
    return (
      <HorizontalView title={i.title}>
        <PrestoTextInput
          value={i.title == "Diff" ? i.value : value}
          key={i.title}
          clearTextOnFocus={false}
          onChange={({ nativeEvent: { text } }) => {
            // i.value = text;
            setValue(text);
            // array[index].value = text
            // setArray((ps) => [...ps].map((ele) => ele.title === i.title ? { ...ele, value: text } : ele))
            i.function(text);
          }}
          style={{
            textInputHeight: 32,
            borderRadius: 6,
          }}
          editable={i.editable}
          keyboardType={i.keyboardType}
          backgroundColor={theme.white}
          theme={theme}
          textInputStyle={{
            borderWidth: 1,
            borderRadius: 4,
            paddingLeft: 10,
            paddingVertical: 5,
            borderColor: theme.extraGrey,
            backgroundColor: theme.white,
          }}
        ></PrestoTextInput>
      </HorizontalView>
    );
  }

  return (
    <DefaultModal
      goBack={goBack}
      modalRef={modalRef}
      keyboardAvoidView={false}
      bgColor={theme.appBackgroundColor}
      height={(50 / 100) * windowHeight}
    >
      <ScrollView>
        <View
          style={{
            padding: 20,
            width: "100%",
            paddingTop: 40,
            alignSelf: "center",
            height: "100%",
          }}
        >
          {showDropDown ? null : (
            <View style={{ zIndex: 1000 }}>
              <HorizontalView title={"Expiry Date"}>
                <TouchableOpacity
                  style={{ width: "100%", alignItems: "center" }}
                  onPress={() => {
                    isMobileWeb ? setOpen(true) : null;
                  }}
                >
                  <PrestoText>
                    {selected
                      ? moment(selected).format("DD-MM-YYYY")
                      : "Select Date"}
                  </PrestoText>
                </TouchableOpacity>
                {open && isMobileWeb ? (
                  <View
                    style={{
                      marginTop: 5,
                      padding: 5,
                      borderWidth: 1,
                      borderRadius: "5px",
                    }}
                  >
                    <YearMonthPicker
                      show={open}
                      onDateChange={(date) => {
                        setOpen(false);
                        setSelected(date);
                        onDateChange(date);
                        if (setExpiryDate) {
                          item.expiry = date;
                        }
                      }}
                    ></YearMonthPicker>
                  </View>
                ) : null}
              </HorizontalView>
            </View>
          )}
          {array.map((i) => {
            return <InputWithText key={i.title} i={i} />;
          })}
          {showDropDown ? (
            <HorizontalView
              title={I18n.t("screen_messages.stock_audit.reason")}
            >
              <Pressable
                onPress={() => {
                  showActionSheetWithOptions(
                    {
                      options: reasons,
                      cancelButtonIndex: 6,
                    },
                    (buttonIndex) => {
                      if (buttonIndex !== reasons?.length - 1) {
                        let reason = _.get(reasons, buttonIndex, null);
                        setSelectedReason(reason);
                        onPressDropDown(reason);
                      }
                    }
                  );
                }}
                style={{
                  width: "100%",
                  height: 40,
                  backgroundColor: theme.screenBackgroundColor,
                  borderRadius: 6,
                  justifyContent: "center",
                  paddingLeft: 10,
                  // marginTop: 20,
                }}
              >
                <PrestoText size={14} color={theme.dark}>
                  {selectedReason}
                </PrestoText>
              </Pressable>
            </HorizontalView>
          ) : null}
        </View>
        {open && isMobile ? (
          <YearMonthPicker
            show={open}
            onDateChange={(date) => {
              setOpen(false);
              setSelected(date);
              onDateChange(date);
              if (setExpiryDate) {
                item.expiry = date;
              }
            }}
          ></YearMonthPicker>
        ) : null}
      </ScrollView>
      <View
        style={{
          // bottom: 20,
          width: "100%",
          padding: 20,
          alignSelf: "center",
          // position: "absolute",
        }}
      >
        <PrestoSolidButton
          size="large"
          height={35}
          width={"100%"}
          buttonStyle="primary"
          titleColor={theme.white}
          title={
            buttonTitle ||
            I18n.t("screen_messages.non_zip_purchases.inward_selected")
          }
          extraStyle={{
            alignSelf: "center",
            marginTop: 10,
          }}
          titleExtraStyle={{ textTransform: "capitalize" }}
          onPress={() => onPressButton()}
        />
      </View>
    </DefaultModal>
  );
}
