import React, { useState, useContext } from "react";
import { View, Dimensions, ScrollView, Platform, FlatList } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import utils from "../../utils/index";
import OrderReturnItemCard from "@presto-screen-components/OrderReturnItemCard/OrderReturnItemCard";
import { alertBox, prestoAlert } from "@presto-common/Alert";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import UserModel from "@presto-component-models/UserModel";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import CustomerNameWithPhone from "@presto-cards/CustomerInfo/CustomerNameWithPhone";
import OrdersHelper from "@presto-helpers/OrdersHelper";
import SessionManager from "@presto-services/features/session/SessionManager";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import Config from "@presto-common/config";
import NavigatorContext from "@presto-contexts/NavigatorContext";

export default function OrderReturn({ route, navigation }) {
  const { Navigator } = useContext(NavigatorContext);
  const params = route.params || {};
  const { order, customer, returnId } = params || {};
  const isWeb = Platform.OS == 'web'
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [loading, setLoading] = useState(false);
  const [isOrderReturned, setIsOrderReturned] = useState(false);
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();


  const alert = (title, message) =>
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );

  const onPressBack = () => {
    navigation.goBack();
  };

  const getTotalDiscount = () => {
    let val = Number(_.get(order, "aggregate_discount"));
    if (isNaN(val)) {
      val = 0;
    }

    val = Number(val.toFixed(2));

    return val;
  };

  const getTotalReturnPrice = () => {
    let price = 0;
    let tax = getTotalTaxAmount();
    let totalDiscount = getTotalDiscount();
    _.forEach(order?.items, (item) => {
      if (item.return_count > 0) {
        price += item.rate * item.return_count;
      }
    });

    let val1 = _.sum([price, parseFloat(tax)]);
    let val2 = val1 - totalDiscount;

    if (val2 <= 0 || isNaN(val2)) {
      val2 = 0;
    }

    val2 = Number(val2.toFixed(2));

    return val2;
  };

  const getTotalTaxAmount = () => {
    let tax = 0;
    _.forEach(order?.items, (item) => {
      if (item.return_count > 0) {
        if (parseFloat(item?.tax_percentage)) {
          let totalAmount = item.return_count * item?.rate;
          tax += (parseFloat(item?.tax_percentage) / 100) * totalAmount;
        } else {
          return (tax += 0);
        }
      }
    });
    return tax?.toFixed(2);
  };

  const getTotalReturnItems = () => {
    let count = 0;
    _.forEach(order?.items, (item) => {
      if (item.return_count > 0) {
        count += item.return_count;
      }
    });
    return count;
  };

  const renderItem = (lineItem, index) => {
    if (lineItem?.return_count > 0) {
      return (
        <OrderReturnItemCard
          key={`${lineItem?.reference_id}`}
          item={lineItem}
          variation=""
          cantEdit={true}
          returnCount={I18n.t("screen_messages.returns.return_count", {
            count: lineItem.return_count,
          })}
          onUpdatecallback={onUpdatecallback}
        />
      );
    } else {
      return null;
    }
  };

  const onUpdatecallback = (item, count) => {
    setLoading(true);
    item.return_count = count;
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const initiateOrderReturn = (type) => {
    setLoading(true);
    let params = {
      id: returnId,
      body: {
        order_return: {
          accepted_quantity: getTotalReturnItems(),
        },
      },
    };
    EmployeeManager.acceptOrderReturn(
      params,
      (res) => {
        const returnAmount = getTotalReturnPrice() * 100;
        if (type === "wallet" && returnAmount > 0) {
          let walletParams = {
            user_id: order?.user_id,
            body: {
              credit: {
                amount: returnAmount,
                comment: "User return Order",
                cashback: true,
                ignore_expiring_balance: true,
                other_data: {},
              },
            },
          };
          EmployeeManager.creditUserWallet(
            walletParams,
            (res) => {
              setIsOrderReturned(true);
              alert(I18n.t("screen_messages.returns.return_initiated"));
              setLoading(false);
            },
            (err) => {
              setLoading(false);
              console.log("wallet err", JSON.stringify(err));
            }
          );
        } else {
          setIsOrderReturned(true);
          setLoading(false);
          alert(I18n.t("screen_messages.returns.return_initiated"));
        }
      },
      (err) => {
        setLoading(false);
        alertBox("", err.code == 2145019 ? I18n.t(`error_messages.return.${err.code.toString()}`) : err.message);
        console.log("err complete", JSON.stringify(err));
      }
    );
  };

  const fetchPrestoSession = async () => {
    setLoading(true);
    return await SessionManager.getEmployee((sessionHeader) => {
      const url =
        PrestoSdk.getHostName() +
        `/employee/v0/orders/${order.id}/download_invoice.json&x-presto-st=RURpL1hjcEdYbFB5VENwR0NVdWpYZGp1SisyNWJWa29hRTJ0akd6TW1iV3ZjODNPR2NGT01VbzBCejJxQnNsT2lCVS9jSkt1Q3ZqSkd5T0x4b1BPQ3JzaVNPNkJ6dGFuRm9PcXEyaE5aQ0NoaHVBK3ZBaHpEQ3ZabzhZeWlJekc2aVJOdUVtQWNsdlRteXR6ZU1YaXo4UE9pTnlhcUpVUVR2Rzd6Q0hSZ3JSN2wzM05LVFV1ZzI0bDU5cjBUQ0lFZkNnYnQ1QnluVzZ6QXZ3SzBXZUFpZUZqWTlsbWRkcUJIUEI5S3ZZSG5Kc04ySForb0xlMms1REZzaVhnQ29wR2xqdGlnYVNYS2dFQVM1N3pVY0hUTHh1bkJvTkhCK2RPUE9XSkMwdGpsSEJjcmRGTTU1Z0JVTGliQm9ZWHJUSWFPTE1uUk5uTStxeGlsLzdQOFlGV3BVeEV4UCsvam4zZ2QyOTN3TjJYcEFDK0JYenVYcWFvWmk0VFNBZXZaMVRiUjREMTNXZTYxdkM4NnB4cnNZcTlaTTl1elZHdFY2c0I1WUQ3am5TK3NsS1pvMXNrSjkrMmxqTDhibzkvZllOQi0tUzlNNWY4RERYeTBoSHVGV2pSVUdoQT09--7be0e34667e4e772aceb0e5ad003a9f92b593f5a`;
      window.open(url, "_self");
      setLoading(false);
      return url;
    })
  };

  const onDownloadInvoice = (printInvoice) => {
    if (isWeb) {
      fetchPrestoSession()
    } else {
      setLoading(true);
      OrdersHelper.downloadInvoiceByEmployee(order, { print: printInvoice }).then(
        (data) => {
          setLoading(false);

          if (data.success) {
            alert(I18n.t("screen_messages.reports.download_successfull"));
          } else {
            alert(I18n.t("screen_messages.download_failed"));
          }
        }
      );
    }
  };

  const goToCustomerSearch = () => {
    if(isMobileWeb){
      navigation.popToTop();
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "CUSTOMER_SEARCH",
        },
      });
    }else{
      navigation.navigate("CustomerSearch");
    }
  };

  let cartInfo = {
    [I18n.t("screen_messages.returns.return_items")]: getTotalReturnItems(),
    [I18n.t("screen_messages.sub_total")]: `${Config.merchant.currency || "ZMW"} ` + getTotalReturnPrice(),
    [I18n.t("screen_messages.discount_text")]: `${Config.merchant.currency || "ZMW"} 0`,
    [I18n.t("screen_messages.total_tax:")]: `${Config.merchant.currency || "ZMW"} ` + getTotalTaxAmount(),
  };

  const RenderBillCard = ({
    leftText,
    rightText,
    rightTextSize,
    fontStyle,
  }) => {
    return (
      <View style={{ flexDirection: "column", width: 350 }}>
        <KeyValueList dict={cartInfo} />
        <VerticalSpacing size={15} />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <PrestoText size={16} fontStyle="500.medium" color={theme.paragraph}>
            {leftText}
          </PrestoText>
          <PrestoText
            size={rightTextSize ? rightTextSize : 16}
            fontStyle={fontStyle ? fontStyle : "500.medium"}
            color={theme.title}
          >
            {rightText}
          </PrestoText>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <View
        style={{
          backgroundColor: "#737682",
          height: "100%",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        }}
      >
        <LoadingContainer loading={loading}>
          <View
            style={{
              backgroundColor: theme.topHeaderInactiveColor,
              height: "100%",
              position: "relative",
            }}
          >
            <ScrollView
              style={{ width: "100%", flex: 1 }}
              contentContainerStyle={{
                paddingTop: 10,
                paddingBottom: 100,
              }}
            >
              <View
                style={{
                  alignSelf: "center",
                  width: "90%",
                  paddingTop: 10,
                }}
              >
                <CustomerNameWithPhone
                  key={customer?.name}
                  customer={new UserModel({ user: customer })}
                  onPress={() => {}}
                />
              </View>
              <View
                style={{
                  paddingHorizontal: theme.containerPadding,
                  paddingTop: 20,
                  flexDirection: "row",
                }}
              >
                <PrestoText
                  size={16}
                  color={theme.white}
                  fontStyle={"500.medium"}
                >
                  {isOrderReturned
                    ? I18n.t("screen_messages.returns.return_complete")
                    : I18n.t("screen_messages.returns.order_return_for")}
                </PrestoText>
                {isOrderReturned ? (
                  <PrestoText
                    size={16}
                    color={theme.white}
                    fontStyle={"500.bold"}
                  >
                    {I18n.t("screen_messages.returns.complete")}
                  </PrestoText>
                ) : null}
              </View>
              {order && !isOrderReturned ? (
                <View style={{ height: "40%" }}>
                  <FlatList
                    data={order?.items}
                    renderItem={({ item, index }) => renderItem(item, index)}
                  />
                </View>
              ) : null}
            </ScrollView>

            <View
              style={{
                backgroundColor: theme.white,
                minHeight: 340,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                width: isMobile || isMobileWeb ? "100%" : 400,
                position: "absolute",
                bottom: 0,
              }}
            >
              <View
                style={{
                  paddingHorizontal: isMobileWeb ? 10 : 30,
                  paddingTop: 20,
                }}
              >
                <RenderBillCard
                  leftText={
                    isOrderReturned
                      ? I18n.t("screen_messages.returns.paid")
                      : I18n.t("screen_messages.cart.to_pay")
                  }
                  rightText={utils.formattedPrice(getTotalReturnPrice())}
                  rightTextSize={24}
                  fontStyle={"600.semibold"}
                />
              </View>

              <View style={styles.footerContainer}>
                <View style={styles.footerInner}>
                  <PrestoSolidButton
                    size="large"
                    buttonStyle="secondary"
                    title={
                      isOrderReturned
                        ? I18n.t("screen_messages.returns.print")
                        : I18n.t("screen_messages.returns.cash_str")
                    }
                    titleColor={theme.white}
                    titleFontStyle={"600.semibold"}
                    titleSize={24}
                    height={60}
                    borderRadius={12}
                    onPress={() =>
                      isOrderReturned
                        ? onDownloadInvoice(true)
                        : initiateOrderReturn("cash")
                    }
                    bgColor={theme.secondary}
                  />
                </View>
                <View style={styles.footerInner}>
                  <PrestoSolidButton
                    size="large"
                    buttonStyle="primary"
                    title={
                      isOrderReturned
                        ? I18n.t("screen_messages.returns.done")
                        : I18n.t("screen_messages.returns.wallet_str")
                    }
                    titleColor={theme.white}
                    titleFontStyle={"600.semibold"}
                    titleSize={24}
                    height={60}
                    borderRadius={12}
                    onPress={() =>
                      isOrderReturned
                        ? goToCustomerSearch()
                        : initiateOrderReturn("wallet")
                    }
                    bgColor={theme.primary}
                  />
                </View>
              </View>
            </View>
          </View>
        </LoadingContainer>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 20,
      flexDirection: "row",
      bottom: 0,
      width: "100%",
      paddingBottom: 10,
      paddingTop:10
    },
    footerInner: {
      width: "50%",
      padding: 5,
    },
  };
}
