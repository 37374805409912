import React, {
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import _ from "lodash";
import utils from "../../utils/index";
import PrestoText from "@presto-components/PrestoText";
import { LineDivider } from "@presto-components/Dividers/Dividers";

export default function CartCheckoutReview({ cart, balanceAmount }) {
  const getCartCount = () => {
    let val = cart?.items
      ? _.sumBy(cart?.items, (item) => {
        return item.quantity;
      })
      : 0;

    return val;
  };



  const getNumber = (val) => {
    let value = Number(val);
    if (isNaN(value)) {
      value = 0;
    }
    return value;
  };

  const getProductDiscount = () => {
    const val = getNumber(Number(cart?.item_promotion_discounts));
    return utils.formattedCurrencyToRoundOff(val);
  };

  const getTotalSavings = () => {
    const val = getNumber(Number(cart?.aggregate_discounts));
    return val.toFixed(2);
  };

  const getCouponDiscount = () => {
    const val = getNumber(Number(cart?.total_discounts) / 100);
    return val.toFixed(2);
  };

  const getTotalPayable = () => {
    const val = getNumber(Number(cart?.total_price) / 100);
    return val.toFixed(2);
  };

  let savingOnOnefreeItem = 0;
  let freeItemAmount = 0;
  if (cart && cart.items) {
    _.forEach(cart.items, (product) => {
      if (product?.other_data?.variation_free_quantity) {
        savingOnOnefreeItem +=
          (product?.quantity *
            parseInt(product?.other_data?.variation_free_quantity) *
            (product?.other_data?.old_price - product?.rate / 100)) /
          (parseInt(product?.other_data?.variation_quantity) +
            parseInt(product?.other_data?.variation_free_quantity));
        freeItemAmount +=
          (product?.quantity *
            parseInt(product?.other_data?.variation_free_quantity) *
            (product?.rate / 100)) /
          (parseInt(product?.other_data?.variation_quantity) +
            parseInt(product?.other_data?.variation_free_quantity));
      }
    });
  }

  let cartInfo = {
    [I18n.t("screen_messages.cart.total_items")]: getCartCount(),
    [I18n.t(
      "screen_messages.cart.cart_sub_total"
    )]: utils.formattedCurrencyToRoundOff(
      cart?.original_gross_amount || cart?.sub_total_price || 0
    ),
    ...(savingOnOnefreeItem + freeItemAmount > 0
      ? {
        [I18n.t(
          "screen_messages.orders.save_on_free_item"
        )]: utils.formattedCurrencyToRoundOff(
          (savingOnOnefreeItem + freeItemAmount) * 100 || 0
        ),
      }
      : {}),
    ...(cart?.discounts?.length > 0
      ? {
        [I18n.t("screen_messages.coupon_discount")]: utils.formattedCurrency(
          getCouponDiscount()
        ),
      }
      : {}),
    [I18n.t("screen_messages.product_discount")]: getProductDiscount(),

    [I18n.t(
      "screen_messages.total_savings.total_savings"
    )]: utils.formattedCurrencyToRoundOff(getTotalSavings()),
    [I18n.t("screen_messages.taxes_str")]: utils.formattedCurrencyToRoundOff(
      cart?.total_taxes || 0
    ),
  };

  const Separator = () => (
    <View style={{ paddingVertical: 3 }}>
      <LineDivider lineHeight={1} width="100%" dividerColor="#D9DBE2" />
    </View>
  );

  return (
    <View style={{ width: "100%" }}>
      <Separator />
      <KeyValueList
        dict={cartInfo}
        lineDividerProps={{
          dividerColor: "#D9DBE2",
        }}
        leftTextProps={{
          fontStyle: "500.medium",
          color: "#848484",
          size: 14,
        }}
        rightTextProps={{
          fontStyle: "500.medium",
          color: "#473C3C",
          size: 14,
        }}
      />
      {
        balanceAmount > 0 ?
          <View
            style={{
              paddingTop: 10,
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <View>
              <PrestoText size={16} fontStyle="500.medium" color={"#848484"}>
                {I18n.t("screen_messages.Balance")}
              </PrestoText>
            </View>

            <View>
              <PrestoText size={16} fontStyle="600.semibold" color={"#473C3C"}>
                {utils.formattedCurrencyToRoundOff(balanceAmount * 100 || 0)}
              </PrestoText>
            </View>
          </View>
          : <></>
      }
      <View
        style={{
          paddingTop: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <View>
          <PrestoText size={16} fontStyle="500.medium" color={"#848484"}>
            {I18n.t("screen_messages.cart.to_pay")}
          </PrestoText>
        </View>

        <View>
          <PrestoText size={16} fontStyle="600.semibold" color={"#473C3C"}>
            {utils.formattedPriceRoundOff(getTotalPayable())}
          </PrestoText>
        </View>
      </View>
    </View>
  );
}
