import Utils from "@presto-services/common/Utils";
import SearchManager from "@presto-services/features/search/SearchManager";
import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";

export function fuzzySearchCategoryItem(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (
    isOfflineEnabledAPI("categoryItems", params.excludeOfflineEnabledAPI) ||
    enableOffline
  ) {
    const obj = new DBCategoryItemManager();
    obj.filter(newParams).then((items) => {
      const response = obj.mockAsSearchServerAPI(items.items, items.totalCount);
      successCallback(response.data);
    });
  } else {
    SearchManager.fuzzySearchCategoryItem(
      newParams,
      successCallback,
      errorCallback
    );
  }
}

function searchCategoryItem(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (
    isOfflineEnabledAPI("categoryItems", params.excludeOfflineEnabledAPI) ||
    enableOffline
  ) {
    const obj = new DBCategoryItemManager();
    obj.filter(newParams).then((items, totalCount) => {
      const response = obj.mockAsSearchServerAPI(items.items, totalCount);
      successCallback(response.data);
    });
  } else {
    SearchManager.searchCategoryItem(newParams, successCallback, errorCallback);
  }
}

function updateItem(params, successCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  console.log(newParams, "lsjdfkfaidsjofjodi")
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }
  if (isOfflineEnabledAPI("categoryItems", params.excludeOfflineEnabledAPI) ||
    enableOffline) {
    console.log("jldsfifdjsiojdfsoji")
    let itemParams = {
      merchant_id: params?.merchant_id,
      ids: [params?.id],
      enableOffline: true,
    }
    console.log(itemParams, "dskfjajsdiofjiosjis")
    fuzzySearchCategoryItem(itemParams, (response) => {
      console.log(JSON.stringify(response), response?.hits?.hits[0]?._source?.stock_count, "ljdfksaifjsdifjsodfjoi")
      const obj = new DBCategoryItemManager();
      obj.updateItem({ ...newParams, stock_count: response?.hits?.hits[0]?._source?.stock_count - params.quantity }).then((items, totalCount) => {
        const response = obj.mockAsSearchServerAPI(items.items, totalCount);
        successCallback(response.data);
      });
    }, (err) => {
      console.log("kdfsjaisdjfiosjdfiojfsid");
    })
  }
}

function updatePrice(params, successCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  console.log(newParams, "lsjdfkfaidsjofjodi")
  if (params.enableOffline) {
    let itemParams = {
      merchant_id: params?.merchant_id,
      ids: [params?.id],
      enableOffline: true,
    }
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
    fuzzySearchCategoryItem(itemParams, (response) => {
      console.log(JSON.stringify(response), response?.hits?.hits[0]?._source, "dfsafwrwefdsfsd")
      const obj = new DBCategoryItemManager();
      let priceObj = response?.hits?.hits[0]?._source?.price;

      obj.updateItem({ ...newParams, stock_count: params.stock_count || response?.hits?.hits[0]?._source?.stock_count }).then((items, totalCount) => {
        const response = obj.mockAsSearchServerAPI(items.items, totalCount);
        successCallback(response.data);
      });

      obj.updatePrice({
        ...newParams, price: JSON.stringify({
          ...priceObj,
          base_price: Number(newParams.price / 100),
          total_price: Number((Number(newParams.price) + Number(priceObj.tax)) / 100),
          stock_count: Number(newParams.stock_count)
        })
      }).then((items, totalCount) => {
        const response = obj.mockAsSearchServerAPI(items?.items, totalCount);
        successCallback(response.data);
      });
    }, (err) => {
      console.log("kdfsjaisdjfiosjdfiojfsid");
    })
  }
}

export default {
  fuzzySearchCategoryItem,
  searchCategoryItem,
  updateItem,
  updatePrice
};
