import React, { useContext } from "react";
import { View, FlatList } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import I18n from "i18n-js";
import { useAssets } from "presto-react-components";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import { ItemCards } from "presto-react-components";
import utils from "../../utils";

const { ItemCard10 } = ItemCards;
const isMobile = utils.isMobile();
 const isMobileWeb = utils.isMobileWeb();

export default function SearchScreen({
  items,
  onPress,
  getIsActive,
  onPressInfo,
  columns = (isMobile || isMobileWeb) ? 2 : 3,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <View style={{ paddingBottom: 40 }}>
      <FlatList
        data={items}
        numColumns={columns}
        columnWrapperStyle={
          (isMobile || isMobileWeb)
            ? undefined
            : {
              flex: 1,
            }
        }
        ItemSeparatorComponent={() => <View style={{ width: 10, height: 10 }} />}
        renderItem={({ item, index }) => {
          const product = new ItemCardModel({ product: item });
          const isLastInColumn = (index + 1) % columns === 0;
          let containerStyle = {},
            defaultTextColor = null;

          if (_.isFunction(getIsActive) && getIsActive(item)) {
            containerStyle = { backgroundColor: theme.primary };
            defaultTextColor = theme.white;
          }

          return (
            <View
              key={index}
              style={{
                marginTop: 10,
                width: `${100 / columns}%`,
                paddingRight: isLastInColumn ? 5 : 5,
                paddingLeft: isLastInColumn ? 5 : 5,
              }}
            >
              <ItemCard10
                product={product}
                onPress={() => onPress(item, null)}
                onPressInfo={() => onPressInfo(item)}
                containerStyle={containerStyle}
                isMobile={(isMobile || isMobileWeb)}
                defaultTextColor={defaultTextColor}
              />
            </View>
          );
        }}
      />
    </View>
  );
}
