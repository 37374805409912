import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import React, { useContext } from "react";
import { Image, View, Pressable } from "react-native";
import moment from "moment";
import _ from "lodash";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import ItemCardPos from "@presto-cards/ItemCards/ItemCard";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import utils from "../../utils/index";

export default function ItemCard(props) {
  const { theme } = useContext(ThemeContext);
  const { images: Images, svgs } = useAssets();
  const {
    item,
    onPress,
    selected,
    onPressButton,
    showRemove,
    onLongPress,
    tabText,
    forPurchases = false,
    onAmountChange,
    onPressAdd,
    onSubtract,
    value,
    onPressSelectBtn,
    selectBtnProps,
    zipImage,
    forMasterCatalog,
    forInventory,
    forOrders,
    forStockAudit,
    auditDate,
    forQuickPrice,
    nearestExpiryFormat,
    itemPurchaseConfiguration,
    setParentLoading,
  } = props || null;

  const defaultPicUrl =
    "https://images.unsplash.com/photo-1584308666744-24d5c474f2ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1660&q=80";

  const getPicUrl = () => (item.pic_url ? item.pic_url : defaultPicUrl);
  let formattedExpiry = "";
  if (item?.other_data?.nearest_expiry) {
    formattedExpiry = moment(item?.other_data?.nearest_expiry).format("MMM-YY");
  }
  const isZipSupplied = function (purchaseConfig) {
    let zipFound = false;
    purchaseConfig?.supplier_prices?.forEach((sp) => {
      if (
        sp.supplier_id ||
        sp?.name?.indexOf("Zip") >= 0 ||
        sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
        sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
        sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
        sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
        sp.supplier_id == "65cddd19179fb03655ed4c75" ||
        sp.supplier_id == "65cd9e96179fb02ff0a255ef"
      ) {
        zipFound = true;
      }
    });
    return zipFound;
  };

  if (forPurchases || forMasterCatalog || forInventory) {
    let variation = {};
    let count = value;
    const product = item;
    return (
      <ItemCardPos
        product={new ItemCardModel({ product, variation, count })}
        forMasterCatalog={forMasterCatalog}
        forPurchases={forPurchases}
        forInventory={forInventory}
        onPress={onPress}
        onAdd={onPressAdd}
        onSubtract={onSubtract}
        onUpdate={onAmountChange}
        updating={false}
        value={value}
        theme={theme}
        selected={selected}
        selectBtnProps={selectBtnProps}
        onPressSelectBtn={onPressSelectBtn}
        isZipSupplied={isZipSupplied(item?.itemPurchaseConfiguration || itemPurchaseConfiguration)}
        zipImage={zipImage}
        onPressButton={onPressButton}
        onLongPress={onLongPress}
        showRemove={showRemove}
        tabText={tabText}
        forOrders={forOrders}
        forStockAudit={forStockAudit}
        auditDate={auditDate}
        forQuickPrice={forQuickPrice}
        nearestExpiryFormat={nearestExpiryFormat}
        setParentLoading={setParentLoading}
      />
    );
  }

  return (
    <Pressable
      onLongPress={() => onLongPress && onLongPress(item)}
      onPress={() => onPress && onPress(item)}
      style={{
        width: "100%",
        minHeight: 50,
        backgroundColor: selected ? theme.topHeaderInactiveColor : theme.white,
        borderRadius: 15,
        padding: 5,
        flexDirection: "row",
        marginBottom: 5,
      }}
    >
      {tabText == "Master Catalog" ? (
        <>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "24%",
              justifyContent: "center",
              alignItems: "flex-start",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
            }}
          >
            <PrestoText
              size={12}
              numberOfLines={2}
              extraStyle={{ paddingLeft: 10 }}
              color={selected ? theme.white : theme.dark}
              fontStyle={"400.bold"}
            >
              {item?.name}
            </PrestoText>
            {item?.other_data?.molecule ? (
              <PrestoText
                extraStyle={{ paddingLeft: 10 }}
                size={12}
                color={selected ? theme.white : theme.dark}
              >
                {item.other_data.molecule}
              </PrestoText>
            ) : null}
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
            }}
          >
            <PrestoText
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            ></PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "10%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
            }}
          >
            <PrestoText
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            >
              {item.other_data.therapy_area_simple}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
            }}
          >
            <PrestoText
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            >
              {item.other_data.company_name}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
            }}
          >
            <PrestoText
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            >
              {item.other_data["sale unit"]}
            </PrestoText>
          </View>

          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "10%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
            }}
          >
            <PrestoText
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            >
              {item.other_data["purchase unit"]}
            </PrestoText>
            <PrestoText
              size={10}
              color={selected ? theme.white : theme.dark}
              fontStyle="400.normal"
            >
              {item.other_data["sale to purchase ratio"]}{" "}
              {item.other_data["sale unit"]}
            </PrestoText>
          </View>
          <View
            style={{
              width: "10%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isZipSupplied(item?.itemPurchaseConfiguration) ? (
              <Image
                source={Images.ZipIcon}
                style={{ width: 30, height: 30, alignSelf: "center" }}
              />
            ) : (
              ""
            )}
          </View>
        </>
      ) : (
        <>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "24%",
              justifyContent: "center",
              alignItems: "flex-start",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
            }}
          >
            <PrestoText
              size={12}
              numberOfLines={2}
              extraStyle={{ paddingLeft: 10 }}
              color={selected ? theme.white : theme.dark}
              fontStyle={"400.bold"}
            >
              {item?.name}
            </PrestoText>
            {item?.other_data?.molecule ? (
              <PrestoText
                extraStyle={{ paddingLeft: 10 }}
                size={12}
                color={selected ? theme.white : theme.dark}
              >
                {item.other_data.molecule}
              </PrestoText>
            ) : null}
          </View>

          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
            }}
          >
            <PrestoText color={selected ? theme.white : theme.dark}>
              {moment(item?.updated_at).format("DD-MM-YYYY")}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingHorizontal: 10,
            }}
          >
            <PrestoText
              fontStyle={"400.semibold"}
              size={16}
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "right" }}
            >
              {item?.stock_count}
            </PrestoText>
            <PrestoText
              extraStyle={{ paddingLeft: 0 }}
              size={12}
              color={selected ? theme.white : theme.dark}
            >
              {item.other_data["sale unit"]}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "15%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingHorizontal: 10,
            }}
          >
            <PrestoText
              fontStyle={"400.semibold"}
              size={16}
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "right" }}
            >
              {formattedExpiry}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "15%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 1,
              paddingHorizontal: 10,
            }}
          >
            <PrestoText
              fontStyle={"400.semibold"}
              size={16}
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "right" }}
            >
              {item?.other_data?.avg_purchase_price
                ? utils.formattedPriceWithoutCurrency(
                  item?.other_data?.avg_purchase_price
                )
                : ""}
            </PrestoText>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              flexDirection: "column",
              width: "15%",
              justifyContent: "center",
              alignItems: "center",
              borderRightColor: theme.lightText,
              borderRightWidth: 0,
              paddingHorizontal: 10,
            }}
          >
            <PrestoText
              fontStyle={"400.semibold"}
              size={16}
              color={selected ? theme.white : theme.dark}
              extraStyle={{ textAlign: "right" }}
            >
              {utils.formattedPriceWithoutCurrency(
                (item.price.base_price + item.price.tax).toFixed(2)
              )}
            </PrestoText>
          </View>
        </>
      )}
      {!item.is_available ? (
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            flex: 1,
            flexDirection: "row",
            // paddingHorizontal: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoSolidButton
            title={
              showRemove
                ? I18n.t("screen_messages.common.remove")
                : tabText == "Master Catalog" && !item.is_available
                  ? I18n.t("screen_messages.common.add_store")
                  : ""
            }
            height={40}
            titleSize={12}
            // width={100}
            extraStyle={{ paddingHorizontal: 5 }}
            titleColor={
              selected
                ? theme.white
                : showRemove
                  ? theme.secondary
                  : theme.primary
            }
            borderColor={
              selected
                ? theme.white
                : showRemove
                  ? theme.secondary
                  : theme.primary
            }
            borderWidth={1}
            borderRadius={7}
            onPress={() => onPressButton && onPressButton(item)}
          />
        </View>
      ) : (
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            flex: 1,
            flexDirection: "row",
            paddingHorizontal: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText
            fontStyle={"400.semibold"}
            size={12}
            color={selected ? theme.primary : theme.primary}
            extraStyle={{ textAlign: "right" }}
          >
            {I18n.t("screen_messages.common.in_store")}
          </PrestoText>
        </View>
      )}
    </Pressable>
  );
}
