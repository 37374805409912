import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import {
  View,
  Dimensions,
  FlatList,
  ScrollView,
  Alert,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import LoadingContainer from "@presto-components/LoadingContainer";
import { prestoAlert } from "../../common/Alert";
import _ from "lodash";
import utils from "../../utils/index";
import SearchBar from "@presto-screen-components/SearchBar/SearchBar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import SearchManager from "@presto-services/features/search/SearchManager";
import { usePagination } from "../../hooks/pagination";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";
import HeaderWithTabs from "@presto-screen-components/Headers/HeaderWithTabs";
import moment from "moment";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { createNavigationContainerRef } from "@react-navigation/native";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useAssets } from "presto-react-components";
import PickerMobile from "@presto-components/PrestoPickers/PickerMobile.buadmin";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import CreateCoupon from "@presto-screens/AdminCoupons/CreateCoupon";
import CouponTable from "@presto-screen-components/CouponList/CouponTable";
import ItemPromotionsCard from "@presto-cards/OffersCard/ItemPromotionsCard";
import NetInfo from "@react-native-community/netinfo";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_PREREQUISITE_QUERY":
      return { ...state, preresuisiteQuery: payload };
    case "SET_ENTITLED_QUERY":
      return { ...state, entitledQuery: payload };
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: payload };
    case "SET_ITEM_NAME":
      return { ...state, itemName: payload };
    case "SET_ITEM_DESCRIPTION":
      return { ...state, itemDescription: payload };
    case "SET_VALUE":
      return { ...state, value: payload };
    case "SET_PREREQUISITE_QTY":
      return { ...state, prerequisiteQty: payload };
    case "SET_MIN_PURCHASE_QTY":
      return { ...state, minPurchaseQty: payload };
    case "SET_MIN_PURCHASE_VALUE":
      return { ...state, minPurchaseValue: payload };
    case "SET_ENTITLEMENT_TYPE":
      return { ...state, entitlementType: payload };
    case "SET_ENTITLEMENT_QTY":
      return { ...state, entitlementQty: payload };
    case "SET_VALUE_TYPE":
      return { ...state, valueType: payload };
    case "SET_ALLOCATION_LIMIT":
      return { ...state, allocationLimit: payload };
    case "SET_ITEM_PROMOTIONS":
      return { ...state, itemPromotions: payload };
    case "SET_PRICE_RULES":
      return { ...state, priceRules: payload };
    case "SET_START_AND_END_TIME":
      return { ...state, timeObject: payload };
    case "SET_SELECTED_PRICE_RULE":
      return { ...state, selectedPriceRule: payload };
    case "RESET":
      return { ...state, ...payload };
  }
}

export default function OffersScreen({ navigation, route }) {
  const { svgs } = useAssets();
  const { selectedFeature } = route?.params;
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeAreaInsets();
  const catalog = useCatalog((state) => state.catalog);
  const [prerequisiteIds, setPrerequisiteIds] = useState([]);
  const [selecting, setSelecting] = useState("prerequisite");
  const [entitlementIds, setEntitlementIds] = useState([]);
  const [searchedItems, setSearchItems] = useState([]);
  const navigationRef = createNavigationContainerRef();
  const [tabs, setTabs] = useState([]);
  const [initialCoupon, setInitialCoupon] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [state, dispatch] = useReducer(reducer, {
    preresuisiteQuery: "",
    entitledQuery: "",
    currentTab: "Offers",
    itemName: "",
    value: 0,
    entitlementType: "",
    valueType: "",
    prerequisiteQty: 0,
    minPurchaseQty: 0,
    minPurchaseValue: 0,
    entitlementQty: 0,
    allocationLimit: 0,
    itemPromotions: null,
    itemDescription: "",
    timeObject: {},
    priceRules: null,
    selectedPriceRule: null,
  });

  const isDesktop = utils.isDesktop();
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();

  let containerWidth = (isMobile || isMobileWeb) ? "100%" : windowWidth - 400;

  let minDate = new Date();
  minDate.setHours("23", "59", "59");

  const {
    preresuisiteQuery,
    entitledQuery,
    currentTab,
    itemName,
    value,
    entitlementType,
    minPurchaseValue,
    minPurchaseQty,
    prerequisiteQty,
    valueType,
    entitlementQty,
    allocationLimit,
    itemPromotions,
    itemDescription,
    timeObject,
    priceRules,
    selectedPriceRule,
  } = state;

  useEffect(() => {
    getPromotions();
    getPriceRules();
    init();
  }, []);

  useEffect(() => {
    if (preresuisiteQuery || entitledQuery) {
      fetchSearchedItems();
    } else {
      setSearchItems([]);
    }
  }, [preresuisiteQuery, entitledQuery]);

  useEffect(() => {
    const tabId = getCurrentActiveTab()?.id;
    if (tabId == "pricerule") {
      navigationRef.navigate("PriceRule", {
        state: state,
        entitlementIds: entitlementIds,
        prerequisiteIds: prerequisiteIds,
      });
    } else if (tabId == "promotion") {
      console.log("hello");
      navigationRef.navigate("ItemPromotion", {
        state: state,
      });
    }
  }, [state, selecting, tabs, entitlementIds, prerequisiteIds]);

  const init = () => {
    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };

    const tempTabs = [
      {
        id: "offers",
        text: I18n.t("screen_messages.reports.offers"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      ...[
        {
          id: "coupons",
          text: I18n.t("screen_messages.reports.coupons"),
        },
        {
          id: "coupon_form",
          text: I18n.t("screen_messages.reports.create_coupon"),
        },
      ].map((item) => {
        return {
          ...item,
          ...tabStyles,
        };
      }),
    ];

    setTabs(tempTabs);
  };

  const fetchSearchedItems = () => {
    let params = {
      catalog_id: catalog?.id,
      search_string: preresuisiteQuery || entitledQuery,
    };
    SearchManager.searchCategoryItem(
      params,
      (response) => {
        setLoading(false);
        let array = _.map(response.hits.hits, (item) => item?._source);
        setSearchItems(array);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const searchPage = useCallback((pageNumber, success, failure) => {
    const params = {
      category_id: "",
      name_asc: true,
      page: pageNumber,
      catalog_id: catalog?.id,
      merchant_id: catalog?.merchant_id,
    };
    SearchManager.searchCategoryItem(
      params,
      (response) => {
        let array = _.map(response.hits.hits, (item) => item?._source);
        success(array);
      },
      (error) => {
        failure(error);
      }
    );
  }, []);
  const [
    results,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
  ] = usePagination(searchPage);

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPressTab = (tab) => {
    let tempTabs = tabs;
    tempTabs = _.map(tempTabs, (tabItem) => {
      let isActive = tabItem.id === tab.id;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const afterPressTab = (tab) => {
    const id = tab.id;

    setActiveRow(null);

    if (id === "coupons") {
      if (isDesktop) navigateCouponDetails();
      fetchCoupons();
    }
  };

  const goToSideNavigation = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SIDE_NAVIGATION",
        screenParams: {
          forwardProps: {
            ...sideNavProps,
            goBack: goBack,
          },
        },
      },
    });
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const navigateCouponDetails = (initialCoupon) => {
    goToSideNavigation();
    setTimeout(
      () => {
        navigationRef.navigate("CouponDetails", {
          initial: initialCoupon,
          setParentLoading: setLoading,
          refresh: refreshFunc,
        });
      },
      (isMobile || isMobileWeb) ? 300 : 0
    );
  };

  const refreshFunc = () => {
    if (isMobile || isMobileWeb) goBack();
    fetchCoupons();
  };

  const fetchCoupons = () => {
    const params = {};
    EmployeeManager.getCoupons(
      params,
      (response) => {
        setCoupons(response.data);
      },
      (error) => {
        setCoupons([]);
        console.log("OfferScreen->afterPressTab->error", error);
      }
    );
  };

  const getCurrentActiveTab = () => {
    let val = _.find(tabs, (e) => e.isActive);
    return val;
  };

  const onNameChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_NAME",
      payload: text,
    });
  };

  const onDescriptionChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_DESCRIPTION",
      payload: text,
    });
  };

  const onChangeTime = (obj) => {
    dispatch({
      type: "SET_START_AND_END_TIME",
      payload: obj,
    });
  };

  const setSelectedPriceRule = (obj) => {
    dispatch({
      type: "SET_SELECTED_PRICE_RULE",
      payload: obj,
    });
  };

  const onValueChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_VALUE",
      payload: text,
    });
  };

  const onQuantityChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_PREREQUISITE_QTY",
      payload: text,
    });
  };

  const onMinValueChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_MIN_PURCHASE_VALUE",
      payload: text,
    });
  };

  const onAllocationChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ALLOCATION_LIMIT",
      payload: text,
    });
  };

  const setItemPromotions = (val) => {
    dispatch({
      type: "SET_ITEM_PROMOTIONS",
      payload: val,
    });
  };

  const setPriceRules = (val) => {
    dispatch({
      type: "SET_PRICE_RULES",
      payload: val,
    });
  };

  const clearState = () => {
    dispatch({
      type: "RESET",
      payload: {
        preresuisiteQuery: "",
        entitledQuery: "",
        itemName: "",
        value: 0,
        entitlementType: "",
        valueType: "",
        prerequisiteQty: 0,
        minPurchaseQty: 0,
        minPurchaseValue: 0,
        entitlementQty: 0,
        allocationLimit: 0,
        itemDescription: 0,
        timeObject: {},
        selectedPriceRule: null,
      },
    });
    setEntitlementIds([]);
    setPrerequisiteIds([]);
  };

  const getPromotions = () => {
    setLoading(true);
    EmployeeManager.getItemPromotions(
      {
        page: 1,
        records_per_page: 10,
        active: 1,
      },
      (res) => {
        setLoading(false);
        setItemPromotions([...res?.data]);
      },
      (err) => {
        setLoading(false);
        console.log("getItemPromotions err :>> ", JSON.stringify(err));
      }
    );
  };

  const getPriceRules = () => {
    setLoading(true);
    let params = {
      page: 1,
      records_per_page: 10,
    };
    EmployeeManager.getAllPriceRules(
      params,
      (res) => {
        let array = _.map(res?.data, (item) => {
          return {
            label: item?.prerequisite_item_ids[0],
            value: item?.id,
          };
        });
        setLoading(false);
        setPriceRules([...array]);
      },
      (err) => {
        setLoading(false);
        console.log("getAllPriceRules err :>> ", JSON.stringify(err));
      }
    );
  };

  const onClickCreatePriceRule = () => {
    let pIds = _.map(prerequisiteIds, (item) => item?.id);
    let eIds = _.map(entitlementIds, (item) => item?.id);
    setLoading(true);
    let params = {
      price_rule: {
        value_type: valueType?.value,
        value: parseFloat(value),
        prerequisite_minimum_quantity: parseInt(minPurchaseQty),
        prerequisite_minimum_purchase_value: parseFloat(minPurchaseValue),
        prerequisite_item_ids: [...pIds],
        prerequisite_variation_ids: [],
        entitlement_type: entitlementType?.value,
        entitled_item_ids: [...eIds],
        entitled_variation_ids: [],
        prerequisite_to_entitlement_quantity_ratio: {
          prerequisite_item_quantity: parseInt(prerequisiteQty),
          entitled_item_quantity: parseInt(entitlementQty),
        },
        allocation_limit: parseInt(allocationLimit),
      },
    };

    EmployeeManager.createPriceRule(
      params,
      (res) => {
        clearState();
        setLoading(false);
        alert(I18n.t("success_messages.price_rule_create_success"));
      },
      (err) => {
        setLoading(false);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  const onClickCreatePromotion = () => {
    setLoading(true);
    let params = {
      item_promotion: {
        price_rule_id: selectedPriceRule?.value,
        category_item_id: selectedPriceRule?.label,
        title: itemName,
        description: itemDescription,
        start_time: timeObject?.startTime,
        end_time: timeObject?.endTime,
        active: true,
        target_type: "order",
      },
    };
    EmployeeManager.createItemPromotion(
      params,
      (res) => {
        clearState();
        setLoading(false);
        alert(I18n.t("success_messages.item_promotion_create_success"));
      },
      (err) => {
        setLoading(false);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  const onSelectEntitlement = (val) => {
    dispatch({
      type: "SET_ENTITLEMENT_TYPE",
      payload: val,
    });
  };

  const onSelectEntitlementQty = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ENTITLEMENT_QTY",
      payload: text,
    });
  };

  const onChangeMinPurchaseQty = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_MIN_PURCHASE_QTY",
      payload: text,
    });
  };

  const onSelectValueType = (val) => {
    dispatch({
      type: "SET_VALUE_TYPE",
      payload: val,
    });
  };

  const onPrerequisiteChange = ({ nativeEvent: { text } }) => {
    setSelecting("prerequisite");
    dispatch({
      type: "SET_PREREQUISITE_QUERY",
      payload: text,
    });
  };

  const onEntitledChange = ({ nativeEvent: { text } }) => {
    setSelecting("entitled");
    dispatch({
      type: "SET_ENTITLED_QUERY",
      payload: text,
    });
  };

  const entitlementTypes = [
    {
      label: "Amount Discount",
      value: "amount_discount",
    },
    {
      label: "Item Discount",
      value: "item_discount",
    },
  ];

  const valueTypes = [
    {
      label: "Fixed",
      value: "fixed",
    },
    {
      label: "Percent",
      value: "percent",
    },
  ];

  const textInputLabelComponent = ({
    textInputStyle,
    width,
    value,
    onChange,
    bgColor,
    keyboardType,
    label,
    placeholder,
  }) => {
    return (
      <View style={{ flexDirection: "column", width: width }}>
        {label ? (
          <PrestoText size={16} fontStyle={"600.bold"} color={theme.white}>
            {label}
          </PrestoText>
        ) : null}
        <PrestoTextInput
          style={{ textInputStyle: textInputStyle }}
          width={width}
          theme={theme}
          placeholder={placeholder}
          value={value}
          keyboardType={keyboardType}
          onChange={onChange}
          backgroundColor={bgColor}
        />
      </View>
    );
  };

  const createPriceRuleComponent = () => {
    return (
      <View
        style={{
          width: windowWidth - 440,
          // height: (71 / 100) * windowHeight,
          backgroundColor: theme.createItemBgColor,
          borderRadius: 20,
          margin: 20,
          paddingHorizontal: 10,
          flexDirection: (isMobile || isMobileWeb) ? "column" : "row",
          paddingBottom: (isMobile || isMobileWeb) ? 100 : 0,
        }}
      >
        <View style={{ width: (isMobile || isMobileWeb) ? "100%" : "50%" }}>
          <VerticalSpacing size={20} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t("screen_messages.add_name"),
            value: itemName,
            label: I18n.t("screen_messages.stock.name"),
            onChange: (val) => onNameChange(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={4} />
          <Heading
            title={I18n.t("screen_messages.price_rules.entitlement_type")}
          />
          <PickerMobile
            dropdownIconStyles={{ color: theme.white }}
            textContainerStyles={{ borderColor: theme.subHeader }}
            items={entitlementTypes}
            labelFieldKey={"label"}
            valueFieldKey={"value"}
            textColor={theme.white}
            selectedItem={entitlementType}
            onSelect={(val) => onSelectEntitlement(val)}
          />
          <VerticalSpacing size={8} />
          <Heading title={I18n.t("screen_messages.price_rules.value_type")} />
          <PickerMobile
            dropdownIconStyles={{ color: theme.white }}
            textContainerStyles={{ borderColor: theme.subHeader }}
            items={valueTypes}
            labelFieldKey={"label"}
            valueFieldKey={"value"}
            textColor={theme.white}
            selectedItem={valueType}
            onSelect={(val) => onSelectValueType(val)}
          />
          <VerticalSpacing size={15} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t(
              "screen_messages.price_rules.enter_minimum_quantity"
            ),
            value: minPurchaseQty,
            keyboardType: "numeric",
            label: I18n.t("screen_messages.price_rules.minimum_quantity"),
            onChange: (val) => onChangeMinPurchaseQty(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: "Search prerequisite items",
            value: preresuisiteQuery,
            label: "Prerequisite Items",
            onChange: (val) => onPrerequisiteChange(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t("screen_messages.price_rules.enter_the_value"),
            value: value,
            label: I18n.t("screen_messages.price_rules.value_str"),
            onChange: (val) => onValueChange(val),
            bgColor: theme.white,
            keyboardType: "numeric",
          })}
        </View>
        <View
          style={{
            width: (isMobile || isMobileWeb) ? "100%" : "50%",
            paddingLeft: (isMobile || isMobileWeb) ? 0 : 20,
          }}
        >
          <VerticalSpacing size={20} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t(
              "screen_messages.price_rules.enter_prerequisite_qty"
            ),
            value: prerequisiteQty,
            keyboardType: "numeric",
            label: I18n.t("screen_messages.price_rules.prerequisite_min_qty"),
            onChange: (val) => onQuantityChange(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t(
              "screen_messages.price_rules.enter_entitled_qty"
            ),
            value: entitlementQty,
            keyboardType: "numeric",
            label: I18n.t("screen_messages.price_rules.entitled_item_qty"),
            onChange: (val) => onSelectEntitlementQty(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t(
              "screen_messages.price_rules.enter_min_purchase"
            ),
            value: minPurchaseValue,
            keyboardType: "numeric",
            label: I18n.t("screen_messages.price_rules.min_purchase_value"),
            onChange: (val) => onMinValueChange(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: I18n.t(
              "screen_messages.price_rules.enter_allocation_limit"
            ),
            value: allocationLimit,
            keyboardType: "numeric",
            label: I18n.t("screen_messages.price_rules.allocation_limit"),
            onChange: (val) => onAllocationChange(val),
            bgColor: theme.white,
          })}
          <VerticalSpacing size={10} />
          {textInputLabelComponent({
            width: "100%",
            placeholder: "Search entitled items",
            value: entitledQuery,
            label: I18n.t("screen_messages.price_rules.emtitled_items"),
            onChange: (val) => onEntitledChange(val),
            bgColor: theme.white,
          })}
        </View>
        <View style={{ position: "absolute", bottom: 25, right: 10 }}>
          <PrestoSolidButton
            bgColor={theme.primary}
            title={I18n.t("screen_messages.create_str")}
            height={50}
            titleSize={16}
            width={100}
            extraStyle={{
              alignSelf: "flex-end",
            }}
            titleColor={theme.white}
            borderColor={theme.primary}
            borderWidth={1}
            borderRadius={7}
            onPress={() => onClickCreatePriceRule()}
          />
        </View>
      </View>
    );
  };

  const Heading = ({ title, containerStyle = {} }) => {
    return (
      <View
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          ...containerStyle,
        }}
      >
        <PrestoText fontStyle="400.semibold" size={16} color={theme.white}>
          {title}
        </PrestoText>
      </View>
    );
  };

  const createItemPromotion = () => {
    return (
      <View
        style={{
          width: windowWidth - 440,
          height: (40 / 100) * windowHeight,
          backgroundColor: theme.createItemBgColor,
          borderRadius: 20,
          margin: 20,
          padding: 10,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
            height: "100%",
            flexDirection: "column",
          }}
        >
          {textInputLabelComponent({
            textInputStyle: { height: 60 },
            width: "100%",
            placeholder: I18n.t("screen_messages.add_name"),
            value: itemName,
            height: 60,
            label: I18n.t("screen_messages.stock.name"),
            onChange: (val) => onNameChange(val),
            bgColor: theme.white,
          })}
          <Heading title={"Start Time"} />
          <View style={{ height: 80 }}>
            <DatePickerComponent
              userDate={
                !_.isEmpty(timeObject?.startTime)
                  ? new Date(timeObject?.startTime)
                  : null
              }
              onDateChange={(value) => {
                onChangeTime({ ...timeObject, startTime: value.toISOString() });
              }}
              textInputStyles={{
                borderRadius: 6,
              }}
              minimumDate={minDate}
              maximumDate={null}
              showTimeInput={true}
              iconSize={35}
              showYearsDropdown={true}
              defaultToToday={false}
            />
          </View>
          <Heading title={"Price Rule"} />
          <PickerMobile
            dropdownIconStyles={{ color: theme.white }}
            textContainerStyles={{ borderColor: theme.subHeader, height: 60 }}
            items={priceRules}
            labelFieldKey={"label"}
            valueFieldKey={"value"}
            textColor={theme.white}
            selectedItem={selectedPriceRule}
            onSelect={(val) => setSelectedPriceRule(val)}
          />
        </View>
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
            height: "100%",
            flexDirection: "column",
          }}
        >
          {textInputLabelComponent({
            textInputStyle: { height: 60 },
            width: "100%",
            placeholder: "Enter description",
            value: itemDescription,
            height: 60,
            label: "Description",
            onChange: (val) => onDescriptionChange(val),
            bgColor: theme.white,
          })}
          <Heading title={"End Time"} />
          <View style={{ height: 80 }}>
            <DatePickerComponent
              userDate={
                !_.isEmpty(timeObject?.endTime)
                  ? new Date(timeObject?.endTime)
                  : null
              }
              onDateChange={(value) => {
                onChangeTime({ ...timeObject, endTime: value.toISOString() });
              }}
              textInputStyles={{
                borderRadius: 6,
              }}
              minimumDate={minDate}
              maximumDate={null}
              showTimeInput={true}
              iconSize={35}
              showYearsDropdown={true}
              defaultToToday={false}
            />
          </View>
          <PrestoSolidButton
            bgColor={theme.primary}
            title={I18n.t("screen_messages.create_str")}
            height={50}
            titleSize={16}
            width={100}
            extraStyle={{
              alignSelf: "flex-end",
              marginTop: 40,
            }}
            titleColor={theme.white}
            borderColor={theme.primary}
            borderWidth={1}
            borderRadius={7}
            onPress={() => onClickCreatePromotion()}
          />
        </View>
      </View>
    );
  };

  const RenderPromotionItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 5,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "20%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.black}
            fontStyle={"400.bold"}
            size={16}
          >
            {I18n.t("screen_messages.stock.name")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            width: "30%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.paragraph}
            fontStyle={"400.bold"}
            size={13}
          >
            {I18n.t("screen_messages.description_str")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.item_promotions.start_time")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.item_promotions.end_time")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.item_promotions.active_str")}
          </PrestoText>
        </View>
      </View>
    );
  };

  const renderPromotionItem = ({ item, index }) => {
    if (isMobile || isMobileWeb) {
      return <ItemPromotionsCard item={item} theme={theme} isMobileWeb={isMobileWeb} />;
    }
    return (
      <View
        key={index}
        style={{
          borderRadius: 20,
          backgroundColor: theme.white,
          width: "100%",
          flexDirection: "row",
          height: 70,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.black}
            fontStyle={"400.bold"}
            size={16}
          >
            {item?.title}
          </PrestoText>
        </View>
        <View
          style={{
            width: "30%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.paragraph}
            fontStyle={"400.bold"}
            size={13}
          >
            {item?.description}
          </PrestoText>
        </View>
        <View
          style={{
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {moment(item?.start_time).format("DD-MM-YYYY")}
          </PrestoText>
        </View>
        <View
          style={{
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {moment(item?.end_time).format("DD-MM-YYYY")}
          </PrestoText>
        </View>
        <View
          style={{
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {item?.active ? "True" : "False"}
          </PrestoText>
        </View>
      </View>
    );
  };

  const onPressButton = (item) => {
    let prerequisite = selecting === "prerequisite";
    let selected = _.some(
      [...entitlementIds, ...prerequisiteIds],
      (i) => i?.id === item?.id
    );
    if (selected) {
      let filtered = _.filter(prerequisiteIds, (i) => i?.id !== item?.id);
      if (prerequisite) {
        setPrerequisiteIds([...filtered]);
      } else {
        setEntitlementIds([...filtered]);
      }
    } else {
      if (prerequisite) {
        dispatch({
          type: "SET_PREREQUISITE_QUERY",
          payload: "",
        });
        setPrerequisiteIds((prev) => {
          return [...prev, item];
        });
      } else {
        dispatch({
          type: "SET_ENTITLED_QUERY",
          payload: "",
        });
        setEntitlementIds((prev) => {
          return [...prev, item];
        });
      }
    }
  };

  const renderItem = ({ item, index }) => {
    let selected = _.some(
      [...entitlementIds, ...prerequisiteIds],
      (i) => i?.id === item?.id
    );
    return (
      <ItemCard
        item={item}
        onPressButton={onPressButton}
        showRemove={selected}
      />
    );
  };

  let sideNavProps = {
    navRef: navigationRef,
    selectedFeature: selectedFeature,
    forwardProps: {
      entitlementIds: entitlementIds,
      prerequisiteIds: prerequisiteIds,
      state: state,
    },
  };

  return (
    <>
      <ScrollView
        contentContainerStyle={{ minHeight: (isMobile || isMobileWeb) ? "100%" : 830 }}
      >
        <LoadingContainer
          bgColor={theme.screenBackgroundColor}
          loading={loading}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            marginTop: insets.top,
          }}
        >
          <HeaderWithTabs
            isActiveStyles={{
              backgroundColor: "#D8D8D8",
            }}
            tabs={tabs}
            onPressTab={onPressTab}
          />
          <View
            style={{
              flexDirection: "row",
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: theme.screenBackgroundColor,
            }}
          >
            {getCurrentActiveTab()?.id == "offers" ? (
              <View
                style={{
                  paddingHorizontal: (isMobile || isMobileWeb) ? 10 : 20,
                  width: containerWidth,
                }}
              >
                {
                  itemPromotions && itemPromotions.length ?
                    <FlatList
                      data={itemPromotions}
                      renderItem={renderPromotionItem}
                      ListHeaderComponent={() => {
                        if (isMobile) return;
                        return <RenderPromotionItemHeader />;
                      }}
                      contentContainerStyle={{ paddingBottom: 80 }}
                    />
                    : <PrestoText size={18} extraStyle={{ paddingTop: 20, paddingLeft: 10 }}>{I18n.t("screen_messages.offers.no_offers_currently")}</PrestoText>
                }
              </View>
            ) : null}
            {getCurrentActiveTab()?.id == "pricerule" ? (
              <ScrollView contentContainerStyle={{ width: containerWidth }}>
                {createPriceRuleComponent()}
                <FlatList
                  contentContainerStyle={{
                    paddingHorizontal: 20,
                  }}
                  data={searchedItems}
                  renderItem={renderItem}
                />
              </ScrollView>
            ) : null}

            {_.includes(
              ["coupons", "coupon_form"],
              getCurrentActiveTab()?.id
            ) ? (
              <View style={{ width: containerWidth }}>
                {getCurrentActiveTab()?.id === "coupon_form" ? (
                  <CreateCoupon
                    initialCoupon={initialCoupon}
                    gotoCoupons={() => {
                      let tempTabs = _.map(tabs, (e) => {
                        return { ...e, isActive: e.id === "coupons" };
                      });
                      setTabs(tempTabs);
                      fetchCoupons();
                    }}
                  />
                ) : null}

                {getCurrentActiveTab()?.id === "coupons" ? (

                  coupons && !coupons.length ?
                    <View style={{ paddingHorizontal: theme.primaryPadding }}>
                      <CouponTable
                        activeRow={activeRow}
                        onPressRow={(coupon) => {
                          let couponItem = coupon;
                          if (couponItem.id === activeRow?.id) {
                            setActiveRow(null);
                            couponItem = null;
                          } else {
                            setActiveRow(couponItem);
                          }
                          navigateCouponDetails(couponItem);
                        }}
                        items={coupons}
                        onEndReached={() => { }}
                      />
                    </View>
                    : <PrestoText size={18} extraStyle={{ paddingTop: 20, paddingLeft: 10 }}>{I18n.t("screen_messages.offers.no_coupons")}</PrestoText>
                ) : null}
              </View>
            ) : null}
            {(isMobile || isMobileWeb) ? null : (
              <View
                style={{
                  width: 400,
                  height: "100%",
                  backgroundColor: theme.screenBackgroundColor,
                }}
              >
                <SideNavigation {...sideNavProps} />
              </View>
            )}
          </View>
        </LoadingContainer>
      </ScrollView>
    </>
  );
}

function getStyles(theme) {
  return {
    container: {},
    modalContainer: {
      alignSelf: "center",
      padding: 20,
      justifyContent: "center",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
  };
}
