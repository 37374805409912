import React, { useContext } from "react";
import { View, FlatList, Pressable } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";
import OrderModel from "@presto-component-models/OrderModel";
import OrderCard from "@presto-cards/OrderCard/OrderCard";
import utils from "../../utils/index";

export default function SalesTable({
  items,
  onEndReached,
  activeOrder,
  onPressRow,
}) {
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();
  const isDesktop = utils.isDesktop();
  const ishighlightedTextEnabled = false;
  const { theme } = useContext(ThemeContext);
  const tableColumns = [
    {
      title: I18n.t("screen_messages.orders.details_title"),
      width: "20%",
      thType: "highlighted",
    },
    {
      title: _.capitalize(I18n.t("screen_messages.orders.items")),
      width: "12%",
    },
    {
      title: I18n.t("screen_messages.datetime.time2"),
      width: "12%",
      viewStyles: {
        marginLeft: -10,
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.datetime.date"),
      width: "12%",
    },
    {
      title: I18n.t("screen_messages.total_savings.total_text"),
      width: "12%",
      viewStyles: {
        paddingLeft: 15,
      },
    },
    {
      title: I18n.t("screen_messages.billing.disc"),
      width: "12%",
      viewStyles: {
        paddingLeft: 0,
      },
    },
    {
      title: I18n.t("screen_messages.common.amount"),
      width: "13%",
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            ...column.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: theme.primaryPadding },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 13,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: { paddingLeft: theme.primaryPadding },
              color: theme.black,
              fontStyle: "400.bold",
              size: 16,
            };
          }

          if (column.title == "Amount") {
            viewStyles = {
              ...viewStyles,
              alignItems: "center",
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText {...textProps}>{column.title}</PrestoText>

              {column.thType === "highlighted" && ishighlightedTextEnabled ? (
                <PrestoText
                  extraStyle={{ paddingLeft: 20 }}
                  color={theme.paragraph}
                  fontStyle={"400.bold"}
                  size={13}
                >
                  Name
                </PrestoText>
              ) : null}
            </View>
          );
        })}
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    const order = new OrderModel({ order: item });
    let friendlyId = order.orderId;
    let customerName = order.user.name;
    let noOfItems = `${order.totalItemsInOrder} Items`;
    let itemNames = _.join(
      _.compact(_.map(_.take(order.items, 2), "name")),
      ", "
    );
    itemNames = `${itemNames} ${I18n.t(
      "screen_messages.orders.number_of_items",
      {
        count: order.totalItemsInOrder > 2 ? order.totalItemsInOrder - 2 : 0,
      }
    )}`;
    let createdAt = moment(order.createdAt);
    let total = Number(order.grossAmount2).toFixed(2);
    let discount = Number(order.discount).toFixed(2);
    let amount = Number(order.orderTotal).toFixed(2);

    const isActive = _.get(activeOrder, "id") === item.id;
    const bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;
    const textColor3 = isActive ? theme.white : theme.black;
    const textColor4 = isActive ? theme.white : "#666666";
    const textColor5 = isActive ? theme.white : "#B6B6B6";

    if (isMobile || isMobileWeb) {
      return <View style={{ marginBottom: 10 }}>
        <OrderCard
          order={order}
          onPress={() => onPressRow(item)}
        />
      </View>
    }
    else {
      return <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          height: 60,
          backgroundColor: bgColor,
          borderRadius: 15,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "20%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor1}
            fontStyle={"600.semibold"}
          >
            {friendlyId}
          </PrestoText>

          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {customerName}
          </PrestoText>
        </View>

        <View
          style={{
            width: `${12 * 5}%`,
            flexDirection: "column",
            marginRight: 20,
            paddingLeft: 5,
          }}
        >
          <View style={{ marginVertical: 4 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View style={{ width: "20%" }}>
                <PrestoText
                  color={textColor3}
                  fontStyle={"400.normal"}
                  size={11}
                >
                  {noOfItems}
                </PrestoText>
              </View>

              <View style={{ width: "20%" }}>
                <PrestoText
                  color={textColor3}
                  fontStyle={"400.normal"}
                  size={11}
                  extraStyle={{ textAlign: "center" }}
                >
                  {createdAt.format("hh:mm A")}
                </PrestoText>
              </View>

              <View style={{ width: "20%" }}>
                <PrestoText
                  color={textColor3}
                  fontStyle={"400.normal"}
                  size={11}
                  extraStyle={{ textAlign: "center" }}
                >
                  {createdAt.format("DD-MM-YYYY")}
                </PrestoText>
              </View>

              <View style={{ width: "20%" }}>
                <PrestoText
                  color={textColor3}
                  fontStyle={"400.normal"}
                  size={11}
                  extraStyle={{ textAlign: "right" }}
                >
                  {total}
                </PrestoText>
              </View>

              <View style={{ width: "20%" }}>
                <PrestoText
                  color={textColor3}
                  fontStyle={"400.normal"}
                  size={11}
                  extraStyle={{ textAlign: "right" }}
                >
                  {discount}
                </PrestoText>
              </View>
            </View>
          </View>

          <PrestoText
            extraStyle={{}}
            color={textColor5}
            fontStyle={"400.normal"}
            size={11}
          >
            {itemNames}
          </PrestoText>
        </View>

        <View
          style={{
            width: "20%",
            flexDirection: "row",
            borderLeftWidth: 1,
            borderLeftColor: "#DBDBDB",
            justifyContent: "flex-end",
            paddingRight: 10,
          }}
        >
          <PrestoText
            extraStyle={{
              paddingRight: 20,
            }}
            color={textColor4}
            fontStyle={"600.semibold"}
          >
            {amount}
          </PrestoText>
        </View>
      </Pressable>
    }
  };

  return (
    <View>
      <FlatList
        stickyHeaderIndices={[0]}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={items}
        renderItem={renderItem}
        ListHeaderComponent={() => {
          if (isMobile || isMobileWeb) return;
          return <RenderItemHeader />;
        }}
        onEndReached={onEndReached}
      />
    </View>
  );
}
