import React, { useContext, useState, useEffect, useCallback } from "react";
import { View, StyleSheet, Switch, Pressable, ScrollView, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useAssets } from "presto-react-components";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import NetworkContext from "@presto-contexts/NetworkContext";
import I18n from "i18n-js";
import CartContext from "@presto-contexts/CartContext";
import utils from "../../utils";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";

const isMobile = utils.isMobile();
const isMobileWeb = utils.isMobileWeb();
const isDesktop = utils.isDesktop();

function MainIconBlockItem({ onPress }) {
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  return (
    <Pressable
      onPress={onPress}
      style={{
        width: (isMobile || isMobileWeb) ? 47 : 61,
        height: 48,
        backgroundColor: theme.primary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginRight: 0.5,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          height: "100%",
          width: "100%",
          padding: 5,
        }}
      >
        <PrestoIcon
          icon={<svgs.StoreIcon1 width={35} height={35} color={theme.white} />}
        />
      </View>
    </Pressable>
  );
}

export function TabBlockItem({
  isActive,
  text,
  onPress,
  onLongPress,
  isActiveStyles,
  tabisActiveStyles,
  tabisActiveTextExtraStyles = {},
  defaultStyles = {},
}) {
  const { theme } = useContext(ThemeContext);
  const textSize =
    (isMobile || isMobileWeb) && isActive ? 14 : isActive ? 20 : 12;
  return (
    <Pressable
      onPress={onPress}
      onLongPress={onLongPress}
      style={{
        minWidth: (isMobile || isMobileWeb) ? 100 : 101,
        height: 36,
        backgroundColor: "#737682",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 0.5,
        marginTop: 12,
        ...defaultStyles,
        ...(isActive && {
          marginTop: 0,
          backgroundColor: theme.primary,
          minWidth: (isMobile || isMobileWeb) ? 100 : 161,
          height: 48,
          ...isActiveStyles,
          ...tabisActiveStyles,
        }),
      }}
    >
      <PrestoText
        size={textSize}
        fontStyle="600.semibold"
        color={theme.white}
        extraStyle={{ ...(isActive && tabisActiveTextExtraStyles) }}
      >
        {text}
      </PrestoText>
    </Pressable>
  );
}

export default function HeaderWithTabs({
  tabs = [],
  onPressTab,
  onLongPress,
  isActiveStyles,
  networkToggle = false,
  onPressNetworkToggle,
  renderRightSideComponent,
}) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const isWeb = Platform.OS == 'web'
  if (!isWeb) {
    const {
      setUserNetworkToOnline,
      setUserNetworkToOffline,
      isOnline,
      userNetworkState,
    } = useContext(NetworkContext);
  }

  const styles = getStyles(theme);

  const toggleNetwork = () => {
    if (isWeb) return newUserNetworkState = "online";
    let newUserNetworkState = null;
    if (userNetworkState) {
      setUserNetworkToOffline();
      newUserNetworkState = "offline";
    } else {
      newUserNetworkState = "online";
      setUserNetworkToOnline();
    }

    if (onPressNetworkToggle) {
      onPressNetworkToggle(newUserNetworkState);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <MainIconBlockItem
          onPress={() => {
            if (Platform.OS == "web") {
              if(isDesktop){
                WebNavigator.emit({
                  event: "replace",
                  params: {
                    screenName: "FEATURE_SELECTION",
                  },
                });
              }else{
                Navigator.emit({
                  event: "replace",
                  params: {
                    screenName: "FEATURE_SELECTION",
                  },
                });
              }
            } else {
              Navigator.emit({
                event: "replace",
                params: {
                  screenName: "FEATURE_SELECTION",
                },
              });
            }
          }}
        />
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            maxHeight: (isMobile || isMobileWeb) ? 45 : 56,
          }}
        >
          {_.map(tabs, (tab, index) => {
            return (
              <TabBlockItem
                key={index}
                {...tab}
                isActiveStyles={isActiveStyles}
                onPress={() => onPressTab({ ...tab, index })}
                onLongPress={() => {
                  if (_.isFunction(onLongPress)) {
                    onLongPress({ ...tab, index });
                  }
                }}
              />
            );
          })}
        </ScrollView>
        {networkToggle ? (
          <View>
            <View
              style={{
                width: 80,
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Switch
                trackColor={{
                  false: theme.white,
                  true: theme.white,
                }}
                thumbColor={theme.primary}
                ios_backgroundColor={theme.white}
                onValueChange={toggleNetwork}
                value={userNetworkState}
              />

              <PrestoText
                color={theme.white}
                size={10}
                fontStyle={"600.semibold"}
                extraStyle={{ paddingTop: 5 }}
              >
                {userNetworkState
                  ? I18n.t("screen_messages.network.online")
                  : I18n.t("screen_messages.network.offline")}
              </PrestoText>
            </View>
          </View>
        ) : null}

        {_.isFunction(renderRightSideComponent)
          ? renderRightSideComponent()
          : null}
      </View>
    </View>
  );
}

function getStyles(props) {
  return StyleSheet.create({
    container: {
      backgroundColor: "#272525",
      maxHeight: 56,
    },
    inner: {
      height: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      backgroundColor: "#272525",
    },
    block: {},
  });
}
