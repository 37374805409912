import React from "react";
import { View, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import PrestoText from "@presto-components/PrestoText";

export default function TextInputWithIcon(props) {
  const { theme, disabled = false, iconContainerStyles = {} } = props;
  const styles = getTheme(theme, props);
  const showSoftInputOnFocus = props.showSoftInputOnFocus ?? true;

  return (
    <View style={[styles.container, props.style]}>
      {props.labelText ? (
        <View style={styles.labelContainer}>
          <PrestoText extraStyle={styles.label}>
            {props.labelText || ""}
          </PrestoText>
        </View>
      ) : null}

      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
          },
          props.textInputStyles,
        ]}
      >
        <TextInput
          editable={!disabled ? true : false}
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={styles.placeHolder.color}
          secureTextEntry={props.secureTextEntry}
          textAlignVertical="top"
          {...props}
          style={styles.textInputStyle}
          onFocus={props.onFocus || null}
          onBlur={props.onBlur || null}
          autoFocus={props.autoFocus || false}
          showSoftInputOnFocus={showSoftInputOnFocus}
          onSubmitEditing={props.onSubmitEditing}
        />
        {props.icon && (
          <View
            style={{ position: "absolute", right: 10, ...iconContainerStyles }}
          >
            {props.icon}
            <TouchableOpacity activeOpacity={0.5}
              onPress={() => props.onPressIcon ? props.onPressIcon() : props.onCancle()}>
              {props.search}
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}

function getTheme(theme, props) {
  return {
    container: {
      width: "100%",
    },
    labelContainer: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginBottom: props.labelMarginBottom || 0,
    },
    label: {
      fontSize: 12,
      width: "100%",
      color: theme.textInputLabelColor || "#000",
    },
    textInputStyle: {
      width: "100%",
      minWidth: 300,
      height: props.textInputHeight || theme.textInputHeight || 50,
      borderRadius: 8,
      fontSize: 16,
      paddingHorizontal: 10,
      backgroundColor: "#ffffff",
      color: "#707070",
      textAlignVertical: "center",
      zIndex: -1,
      opacity: !props?.disabled ? 1 : 0.4,
      ...props?.textInputStyle,
    },
    placeHolder: {
      color: theme.placeHolderColor || "#707070",
    },
  };
}
