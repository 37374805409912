import React, { useState, useContext, useCallback } from "react";
import { View, Dimensions, ScrollView, PermissionsAndroid, Platform } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import OrderManager from "@presto-services/features/orders/OrderManager";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import utils from "../../utils/index";
import LineItemCard from "@presto-cards/LineItemCard/LineItemCard";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import useFocusEffect from "@presto-common/useFocusEffect";
import moment from "moment";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ClientManager from "@presto-services/features/client/ClientManager";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";

export default function CustomerDetails({ route, navigation }) {
  const params = route.params || {};
  const {
    sideNavigationRef,
    initialCustomer,
    parentLoading,
    setParentLoading,
    onSaveCustomerInfoCallback,
  } = params || {};
  const { theme } = useContext(ThemeContext);
  const isWeb = Platform.OS == 'web'
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [customer, setCustomer] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [analyticsFormat, setAnalyticsFormat] = useState("summary");
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();


  const getLastOrderedDateTime = () => {
    if (_.get(customer, "last_order_time")) {
      const orderDate = moment(_.get(customer, "last_order_time"));
      return orderDate.format("DD-MM-YYYY");
    }
    return "";
  };

  const getClient = () => {
    const onSuccess = (response) => {
      setCustomer(response.data);
    };
    const onError = (error) => {
      setParentLoading(false);
      console.log("CustomerDetails->getClient->error", error);
    };
    setParentLoading(true);
    ClientManager.getClient(
      { user_id: initialCustomer.id, merchant_id: initialCustomer.merchant_id },
      onSuccess,
      onError
    );
  };

  const getWalletBalance = () => {
    let currentBalance = Number(_.get(wallet, "current_balance"));
    let expiryBalance = Number(_.get(wallet, "expiry_balance"));

    if (isNaN(currentBalance)) {
      currentBalance = 0;
    }
    if (isNaN(expiryBalance)) {
      expiryBalance = 0;
    }

    let balance = _.add(currentBalance, expiryBalance) / 100;

    return balance;
  };

  const summaryRows =
    analyticsFormat === "detailed"
      ? {
        [I18n.t("screen_messages.orders.total_orders")]: _.get(
          customer,
          "user_order_stat.total_count"
        ),
        [I18n.t(
          "screen_messages.orders.total_order_value"
        )]: utils.formattedCurrency(
          utils.formatPriceToLocal(
            _.get(customer, "user_order_stat.total_bill_amount", 0) / 100
          )
        ),
        [" "]: "",
        [I18n.t(
          "screen_messages.orders.total_discount_received"
        )]: utils.formattedCurrency(
          utils.formatPriceToLocal(
            _.get(customer, "user_order_stat.total_discount_amount", 0) / 100
          )
        ),
        [I18n.t(
          "screen_messages.orders.last_purchased_date"
        )]: getLastOrderedDateTime(),
        [" "]: "",
        [I18n.t(
          "screen_messages.wallet.wallet_balance"
        )]: utils.formattedCurrency(
          utils.formatPriceToLocal(getWalletBalance())
        ),
      }
      : {};

  useFocusEffect(
    useCallback(() => {
      if (initialCustomer) {
        init();
      } else {
        setAnalyticsFormat("summary");
        setCustomer(null);
      }
    }, [initialCustomer])
  );

  const init = () => {
    setParentLoading(true);
    setAnalyticsFormat("detailed");
    getClient();

    WalletManager.getEmployeeWalletInfo(
      { id: initialCustomer.id },
      (response) => {
        setParentLoading(false);
        setWallet(response.data);
      },
      (error) => {
        setParentLoading(false);
        console.log("customerWalletInfo error", error);
      }
    );
  };

  const handleOnPressDownloadReportPdf = () => {
    if (analyticsFormat === "detailed") {
      navigateToEditCustomer();
    }
  };

  const handleOnPressPrintSummary = () => {
    if (analyticsFormat === "detailed") {
      navigateToSalesReports({
        filterOptions: {
          searchValue: customer.phone_number,
        },
      });
    }
  };

  const navigateToSalesReports = (params) => {
    if (isWeb && isDesktop) {
      WebNavigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "sales_reports_management",
            },
            ...params,
          },
        },
      });
    } else {
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "sales_reports_management",
            },
            ...params,
          },
        },
      });
    }
  };

  const navigateToEditCustomer = () => {
    navigation.navigate("AddCustomer", {
      initialForm: customer,
      parentLoading,
      setParentLoading,
      sideNavigationRef,
      onSaveCustomerInfoCallback,
    });
  };

  const RenderSummary = ({ }) => {
    return (
      <View style={{ flexDirection: "column" }}>
        <KeyValueList
          dict={summaryRows}
          lineDividerProps={{ dividerColor: "#D9DBE2" }}
          leftTextColor="#848484"
          rightTextColor="#473C3C"
          leftTextProps={{
            size: 16,
            fontStyle: "500.medium",
          }}
          rightTextProps={{
            size: 16,
            fontStyle: "500.medium",
          }}
          isWidth={false}
        />
        <VerticalSpacing size={20} />
      </View>
    );
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: (isMobile || isMobileWeb) ? 0 : 18,
          borderTopRightRadius: (isMobile || isMobileWeb) ? 0 : 18,
          height: windowHeight,
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1, height: windowHeight }}
          contentContainerStyle={{
            paddingHorizontal: theme.primaryPadding,
            paddingTop: 30,
          }}
        >
          <View>
            {customer ? (
              <View>
                <View>
                  <PrestoText
                    extraStyle={{ paddingTop: 5 }}
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {customer.name}
                  </PrestoText>

                  <PrestoText
                    extraStyle={{ paddingTop: 5 }}
                    color={theme.white}
                    fontStyle={"500.medium"}
                    size={18}
                  >
                    {customer.phone_number}
                  </PrestoText>

                  <PrestoText
                    extraStyle={{ paddingTop: 5 }}
                    color={theme.white}
                    fontStyle={"500.medium"}
                    size={18}
                  >
                    {customer.email}
                  </PrestoText>

                  <PrestoText
                    extraStyle={{ paddingTop: 5 }}
                    color={theme.white}
                    fontStyle={"500.medium"}
                    size={18}
                  >
                    {customer.birthday ? `DOB: ${moment(customer.birthday).format("DD MMM, YYYY")}` : ""}
                  </PrestoText>

                  <PrestoText
                    extraStyle={{ paddingTop: 5 }}
                    color={theme.white}
                    fontStyle={"500.medium"}
                    size={18}
                  >
                    {customer.gender}
                  </PrestoText>
                </View>
              </View>
            ) : (
              <PrestoText
                color={theme.white}
                fontStyle={"400.normal"}
                size={12}
              >
                {I18n.t("screen_messages.customers.please_select_an_customer")}
              </PrestoText>
            )}
          </View>
        </ScrollView>

        <ScrollView
          style={{
            backgroundColor: theme.white,
            minHeight: 0,
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: "100%",
            bottom: 0,
          }}
        >
          <View
            style={{ paddingHorizontal: theme.primaryPadding, paddingTop: 35 }}
          >
            <RenderSummary />
          </View>

          <View style={styles.footerContainer}>
            <View style={styles.footerInner}>
              {analyticsFormat === "detailed" ? (
                <View
                  style={{
                    width: "100%",
                    paddingTop: 10,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ flex: 1, marginRight: 5 }}>
                    <PrestoSolidButton
                      size="large"
                      buttonStyle="secondary"
                      title={
                        analyticsFormat === "summary"
                          ? I18n.t(
                            "screen_messages.reports.download_report_pdf"
                          )
                          : analyticsFormat === "detailed"
                            ? I18n.t("screen_messages.common.edit_text")
                            : ""
                      }
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={isWeb ? 15 : 20}
                      height={60}
                      borderRadius={12}
                      onPress={handleOnPressDownloadReportPdf}
                      bgColor="#F17B8D"
                      numberOfLines={2}
                      extraStyle={{
                        paddingLeft: theme.primaryPadding,
                        justifyContent: "flex-start",
                      }}
                    />
                  </View>

                  <View style={{ marginLeft: 5, ...(!isWeb && { flex: 1 }) }}>
                    <PrestoSolidButton
                      size="large"
                      buttonStyle="secondary"
                      title={
                        analyticsFormat === "summary"
                          ? I18n.t("screen_messages.reports.print_summary")
                          : analyticsFormat === "detailed"
                            ? I18n.t("screen_messages.orders.show_all_orders")
                            : ""
                      }
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={isWeb ? 15 : 20}
                      height={60}
                      borderRadius={12}
                      onPress={handleOnPressPrintSummary}
                      bgColor="#5F98A4"
                      numberOfLines={2}
                      extraStyle={{
                        paddingHorizontal: theme.primaryPadding,
                        justifyContent: "flex-start",
                      }}
                      titleExtraStyle={{
                        textAlign: "left",
                      }}
                    />
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: theme.primaryPadding,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
  };
}
