import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";
import _ from "lodash";

const PurchaseOrdersResource = function (PrestoSdk, $http) {
  function create(params) {
    let url = PrestoSdk.getHostName() + "/employee/v1/purchase_orders.json";
    return $http.post(url, params);
  }

  function show(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      ".json";
    return $http.get(url);
  }

  function addItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/add_item.json";
    return $http.put(url, params);
  }

  function removeItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/remove_item.json";
    return $http.put(url, params);
  }

  function updateItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/update_item.json";

    return $http.put(url, params);
  }

  function markAsWaitingForApproval(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/mark_as_awaiting_approval.json";
    return $http.put(url);
  }

  function approve(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/approve.json";
    return $http.put(url);
  }

  function download(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/download_purchase_order.json";
    return $http.get(url);
  }

  function reject(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/reject.json";
    return $http.put(url, params);
  }

  function list(params) {
    let url = PrestoSdk.getHostName() + "/employee/v1/purchase_orders.json";

    let queryString = "",
      queryStringItems = [];

    if (_.has(params, "purchase_orders")) {
      let obj = params.purchase_orders;
      if (_.get(obj, "status")) {
        queryStringItems.push(`purchase_orders[status]=${obj.status}`);
      }
      if (_.get(obj, "type")) {
        queryStringItems.push(`purchase_orders[type]=${obj.type}`);
      }

      if (_.get(obj, "supplier_id")) {
        queryStringItems.push(
          `purchase_orders[supplier_id]=${obj.supplier_id}`
        );
      }
    }

    queryString = _.join(queryStringItems, "&");
    url += `?${queryString}`;

    return $http.get(url);
  }

  function updateDeliveredQuantity(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/update_delivery_details.json";
    return $http.put(url, params);
  }

  function markAsDelivered(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/mark_as_delivered.json";
    return $http.put(url, params);
  }

  function close(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/close.json";
    return $http.put(url, params);
  }

  function order(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/purchase_orders/" +
      params.id +
      "/order.json";
    return $http.get(url);
  }

  function listDraftPo(params) {
    var url =
      PrestoSdk.getHostName() + "/employee/v1/draft_purchase_orders.json";

    if (params.status) {
      url += `?status=${params.status}`;
    }

    return $http.get(url, params);
  }

  function createDraftPo(params) {
    var url =
      PrestoSdk.getHostName() + "/employee/v1/draft_purchase_orders.json";
    return $http.post(url, params);
  }

  function markDraftPoAsConfirmed(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/draft_purchase_orders/${params.id}/mark_as_confirmed.json`;
    return $http.put(url, params);
  }

  function getBatchAndExpiry(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/inventory_batches.json?inventory_batch_ids=${params.ids}`;
    if (params.merchant_id) {
      url += `&merchant_id=${params.merchant_id}`
    }

    return $http.get(url);
  }

  return {
    create,
    show,
    addItem,
    removeItem,
    updateItem,
    markAsWaitingForApproval,
    approve,
    download,
    reject,
    list,
    updateDeliveredQuantity,
    markAsDelivered,
    close,
    order,
    listDraftPo,
    createDraftPo,
    markDraftPoAsConfirmed,
    getBatchAndExpiry,
  };
};

export default PurchaseOrdersResource(PrestoSdk, $http);
