import DBBaseManager from "@presto-db-managers/DBBaseManager";
import _ from "lodash";
import { Platform } from "react-native";
import { fuzzySearchCategoryItem } from "../datasources/CategoryDataSources";

export default class DBCategoryItemManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "category_items";
    this.fillables = [
      "id",
      "keywords",
      "description",
      "pic_url",
      "name",
      "reference_id",
      "other_data",
      "listing_order",
      "sold_out",
      "stock_count",
      "pre_orderable",
      "catalog_id",
      "merchant_id",
      "category_id",
      "updated_at",
      "created_at",
      "price",
      "variations",
      "on_offer",
      "pictures",
      "zip_supplied",
    ];
    this.datatypes = {
      keywords: "JSON",
      other_data: "JSON",
      stock_count: "INTEGER",
      price: "JSON",
      variations: "JSON",
      on_offer: "BOOL",
      pictures: "JSON",
    };
  }

  filter(params = {}) {
    const isWeb = Platform.OS == 'web'
    let whereQueries = [],
      orWhereQueries = [],
      queryArgs = [],
      orQueryArgs = [],
      limitQuery = "",
      limitQueryArgs = [],
      sortOrder = "ASC";

    if (params.sortOrder) {
      sortOrder = params.sortOrder;
    }
    if (params.catalog_id) {
      whereQueries.push("catalog_id=?");
      queryArgs.push(params.catalog_id);
    }

    if (params.stockCountRange) {
      whereQueries.push("CAST(stock_count AS INTEGER) < 10");
      //queryArgs.push(params.stockCountRange.lte);
    }

    if (!_.isEmpty(params.search_string)) {
      whereQueries.push("name LIKE ?");
      queryArgs.push(`%${params.search_string}%`);
    }

    if (!_.isEmpty(params.id)) {
      whereQueries.push("id=?");
      queryArgs.push(params.id);
    }

    if (!_.isEmpty(params.ids)) {
      whereQueries.push("id IN " + "('" + params.ids.join("', '") + "')");
      //queryArgs.push();
    }

    if (!_.isEmpty(params.ref_ids)) {
      whereQueries.push(
        "reference_id IN " + "('" + params.ref_ids.join("', '") + "')"
      );
      //queryArgs.push();
    }

    console.log("HEEEEE");

    if (_.has(params, "on_offer")) {
      whereQueries.push("on_offer=?");
      queryArgs.push("" + params.on_offer + "");
    }

    if (!_.isEmpty(params.variation_id)) {
      whereQueries.push("variations LIKE ?");
      queryArgs.push(`%${params.variation_id}%`);
    }

    if (!_.isEmpty(params.or_reference_id)) {
      orWhereQueries.push("reference_id LIKE ?");
      orQueryArgs.push(`%${params.or_reference_id}%`);
    }

    if (params.size) {
      limitQuery = "LIMIT ?";
      limitQueryArgs = params.size == 25 ? [params.size] : [params.size];
      if (params.page) {
        limitQuery += " OFFSET ?";
        limitQueryArgs.push((params.page - 1) * params.size);
      }
    }

    const enableJSONSearch = true;
    if (enableJSONSearch) {
      /*if (!_.isEmpty(params.or_therapy_area_simple)) {
        orWhereQueries.push(
          "JSON_extract(other_data, '$.therapy_area_simple') LIKE ?"
        );
        orQueryArgs.push(`%${params.or_therapy_area_simple}%`);
      }*/

      if (!_.isEmpty(params.or_molecule)) {
        orWhereQueries.push("other_data LIKE ?");
        orQueryArgs.push(`%${params.or_molecule}%`);
      }

      /*if (!_.isEmpty(params.or_company_name)) {
        orWhereQueries.push(
          "JSON_extract(other_data, '$.company_name') LIKE ?"
        );
        orQueryArgs.push(`%${params.or_company_name}%`);
      }*/
    }

    let whereQuery = "",
      orWhereQuery = "";
    if (whereQueries.length) {
      let temp = _.join(whereQueries, " AND ");
      whereQuery = ` WHERE ${temp}`;
    }

    if (orWhereQueries.length) {
      let temp = _.join(orWhereQueries, " OR ");
      orWhereQuery = whereQueries.length ? ` OR (${temp})` : ` WHERE ${temp}`;
    }

    let sortField = "name";
    if (params.stock_count_asc || params.stock_count_desc) {
      sortField = "CAST(stock_count AS INTEGER)";
    }

    if (params.zip_asc || params.zip_desc) {
      sortField = "zip_supplied";
    }

    let query = `SELECT * FROM ${this.tableName} ${whereQuery} ${orWhereQuery} ORDER BY ${sortField} ${sortOrder} ${limitQuery};`;
    if (params.stockCountRange) {
      query = `SELECT * FROM ${this.tableName} ${whereQuery} ${orWhereQuery} ORDER BY ${sortField} ${sortOrder} ${limitQuery};`;
    }
    let allQueryArgs = [...queryArgs, ...orQueryArgs, ...limitQueryArgs];
    console.log("DBCategoryItemManager->filter->query", query);
    console.log(
      "DBCategoryItemManager->filter->allQueryArgs",
      JSON.stringify(allQueryArgs)
    );
    return new Promise((resolve, reject) => {
      if (isWeb) resolve({ items: [], totalCount: 0 })
      this.databaseConnection.exec(
        [
          {
            sql: query,
            args: allQueryArgs,
          },
        ],
        false,
        (error, result) => {
          console.log("Error == ");
          console.log(error);
          // console.log(JSON.stringify(result));
          console.log("Results -------- ");
          let query1 = `SELECT COUNT(*) FROM ${this.tableName} ${whereQuery} ${orWhereQuery}`;
          let allQueryArgs1 = [...queryArgs, ...orQueryArgs];
          this.databaseConnection.exec(
            [
              {
                sql: query1,
                args: allQueryArgs1,
              },
            ],
            false,
            (error1, result1) => {
              let items1 = _.map(_.get(result1, "0.rows"), (e) => {
                return e["COUNT(*)"];
              });

              console.log(
                "DBCategoryItemManager->filter->items count",
                items1[0]
              );

              if (error) {
                reject(error);
              } else {
                let items = _.map(_.get(result, "0.rows"), (e) =>
                  this.applyDatatypeToObject(e)
                );

                console.log("DBCategoryItemManager->filter->items count", [
                  items?.length,
                ]);
                resolve({ items: items, totalCount: items1[0] });
              }
            }
          );
        }
      );
    });
  }

  getBasePrice(item) {
    let val = _.get(item, "price.base_price", 0);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  getTotalPrice(item) {
    let val = _.get(item, "price.total_price", 0);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  getTax(item) {
    let val = _.get(item, "price.tax", 0);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  getVariation(variationId) {
    let sql = `SELECT *
        FROM
        ${this.tableName}
        WHERE variations LIKE '%${variationId}%';`;
    return this.asyncExec({ sql, args: [] }).then(({ rows }) => {
      let row = _.first(rows);
      let item = _.find(_.get(row, "variations"), ["id", variationId]);
      return item;
    });
  }

  getVariationOuterItem(variationId) {
    let sql = `SELECT *
        FROM
        ${this.tableName}
        WHERE variations LIKE '%${variationId}%';`;
    return this.asyncExec({ sql, args: [] }).then(({ rows }) => {
      let row = _.first(rows);
      return row;
    });
  }

  updateItem({ id, merchant_id, stock_count }) {
    console.log(stock_count, id, merchant_id, "ldskjfaiosdfiojsaio")
    let query = `UPDATE ${this.tableName} SET stock_count=? WHERE id=? AND merchant_id=?;`;
    let args = [stock_count, id, merchant_id];
    return this.asyncExec({ sql: query, args: args })
      .then(({ rowsAffected, rows }) => {
        console.log(rowsAffected, rows, "fkjasjfdijoaidfjsjio")
        if (rowsAffected === 0) {
          const error = new Error(
            `DBCartItemmanager.updateItem failed as not records exists\n${query}\n${args}`
          );
          throw error;
        }
        return _.first(rows);
      })
      .catch((e) => {
        console.log(e, "dlskjafijsiodfjiojf")
        throw e;
      });
  }

  updatePrice({ id, merchant_id, price }) {
    let query = `UPDATE ${this.tableName} SET price=? WHERE id=? AND merchant_id=?;`;
    let args = [price, id, merchant_id];
    return this.asyncExec({ sql: query, args: args })
      .then(({ rowsAffected, rows }) => {
        if (rowsAffected === 0) {
          const error = new Error(
            `DBCartItemmanager.updateItem failed as not records exists\n${query}\n${args}`
          );
          throw error;
        }
        let itemParams = {
          merchant_id: merchant_id,
          ids: [id],
          enableOffline: true,
        }
        fuzzySearchCategoryItem(itemParams, (response) => {
          console.log(JSON.stringify(response), response?.hits?.hits[0]?._source, "ddfdfsfsafwrwefdsfsd")
        }, (err) => {
          console.log(err);
        })
        return _.first(rows);
      })
      .catch((e) => {
        console.log(e, "dlskjdfsdadfafijsiodfjiojf")
        throw e;
      });
  }
}
