let itemExpiryMap = {};
import React, { useContext, useState, useReducer, useEffect } from "react";
import { View, FlatList, Pressable, Image, Dimensions, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import utils from "../../utils";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import YearMonthSelect from "../../components/yearMonthSelect"
import YearMonthPicker from "@presto-components/yearMonthPicker";
import CheckBoxWithText from "@presto-components/CheckBoxes/CheckBoxWithText";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import PrestoAmountInput from "@presto-components/AmountInputs/PrestoSmallAmountInput";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import asyncify from "@presto-common/Asyncify";
import { AxiosError } from "axios";
import { alertBox, prestoAlert } from "@presto-common/Alert";
import PurchaseItemCard from "@presto-cards/ItemCards/PurchaseItemCard";
import ItemCard from "@presto-cards/ItemCards/ItemCard";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import AsyncStorage from "@react-native-community/async-storage";
import { useAssets } from "presto-react-components";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { OfflineSync } from "@presto-helpers/OfflineSync";
import UserContext from "@presto-contexts/UserContext";
import Config from "@presto-common/config";
import { Mobile } from "aws-sdk";

export default function MyPurchaseRequests({
  items,
  onEndReached,
  activeRow,
  onPressRow,
  onPressActionButton,
  suppliers,
  column2Title = I18n.t("screen_messages.suppliers.title2"),
  isIncomingScreen,
  purchaseOrderObject,
  loadPurchaseOrders,
  zipB2bOrders,
  zipB2bOrderItems,
  showClosed
}) {
  const isWeb = Platform.OS == 'web'
  const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);
  const [focusedItem, setFocusedItem] = useState(null);
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();
  const { images } = useAssets();
  const windowWidth = Dimensions.get("window").width;
  let isTabAndAbove = windowWidth > 500 ? true : false;
  /*const tableColumns = [
    {
      title: I18n.t("screen_messages.purchase_requests.requested_on"),
      width: "15%",
      thType: "highlighted",
    },
    {
      title: column2Title,
      width: "55%",
      viewStyles: {
        alignItems: "flex-start",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_requests.type"),
      width: "15%",
      viewStyles: {
        alignItems: "center",
        paddingRight: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.cart.total_price"),
      width: "15%",
      viewStyles: {
        alignItems: "flex-end",
        paddingRight: theme.primaryPadding,
      },
    },
  ];*/
  const tableColumns = [
    {
      title: I18n.t("screen_messages.products.sku_1"),
      width: "33%",
      thType: "highlighted",
      textAlign: "left",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.orders.in_stock"),
      width: "12%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.last_month_sale"),
      width: "12%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.supply_unit"),
      width: "12%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t(
        "screen_messages.purchase_configurations.purchase_supply_unit"
      ),
      width: "15%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.stock.price"),
      width: "13%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
  ];

  const tableIncomingColumns = [
    {
      title: I18n.t("screen_messages.products.sku_1"),
      width: "22%",
      thType: "highlighted",
      textAlign: "left",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.expiry"),
      width: "16%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.batch_invoice"),
      width: "13%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.product_cost"),
      width: "12%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.product_tax"),
      width: "8%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.quantity_ordered"),
      width: "8%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.quantity_received"),
      width: "9%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.actions"),
      width: "13%",
      thType: "highlighted",
      textAlign: "center",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
  ];

  const getTotalDeliveredQty = (po) => {
    let val = 0;

    if (po) {
      val = _.sumBy(po.items, (item) => {
        let value = Number(item.delivered_quantity);

        return value;
      });
    }

    return val;
  };

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...column.viewStyles,
          };

          let textProps = {
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 10,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ...column.viewStyles,
            };
            textProps = {
              extraStyle: {
                paddingLeft: theme.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 12,
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText
                {...textProps}
                extraStyle={{
                  textAlign: textProps.extraStyle.textAlgin,
                  width: "100%",
                }}
              >
                {column.title}
              </PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  useEffect(() => {
    if (!zipB2bOrders) {
      if (loadPurchaseOrders) {
        loadPurchaseOrders();
      }
    }
  }, []);

  const RenderIncomingItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableIncomingColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...column.viewStyles,
          };

          let textProps = {
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 10,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ...column.viewStyles,
            };
            textProps = {
              extraStyle: {
                paddingLeft: theme.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 10,
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText
                {...textProps}
                extraStyle={{
                  textAlign: textProps.extraStyle.textAlgin,
                  width: "100%",
                }}
              >
                {column.title}
              </PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const getTotalPrice = (purchaseOrder) => {
    let val = 0;

    if (purchaseOrder) {
      val =
        Number(purchaseOrder.total_price) -
        Number(purchaseOrder.undelivered_amount);

      //val = val / 100;
    }

    if (isNaN(val) || val < 0) {
      val = 0;
    }

    return val;
  };

  const getSupplierById = (supplierId) => _.find(suppliers, ["id", supplierId]);

  const getItemsCountText = (item) => {
    let totalItems = item.items.length;
    let itemNames = _.join(
      _.compact(_.map(_.take(item.items, 2), "name")),
      ", "
    );
    itemNames = `${itemNames} ${I18n.t(
      "screen_messages.orders.number_of_items",
      {
        count: totalItems > 2 ? totalItems - 2 : 0,
      }
    )}`;

    return itemNames;
  };

  const isZipSupplied = function (item) {
    let zipFound = false;
    if (
      item?.sourceItem?.other_data?.zip_supplied == "1" ||
      item?.sourceItem?.other_data?.zip_supplied == 1
    ) {
      zipFound = true;
    }
    return zipFound;
  };

  const renderItem = ({ item, index }) => {
    const order_no = item?.id;
    const order_date = item?.created_at;
    const isActive = false;
    const isAddedToBucket = false;
    let bgColor = isActive ? "#6D707B" : theme.white;
    if (isAddedToBucket) {
      bgColor = theme.primary;
    }

    const textColor1 =
      isActive || isAddedToBucket ? theme.white : theme.primary;
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor3 = isActive || isAddedToBucket ? theme.white : theme.black;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";

    return (
      <>
        <View
          style={{
            paddingTop: 10,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: (isMobile || isMobileWeb) ? "100%" : null,
          }}
        >

          <View
            style={{
              flexGrow: 1,
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              padding: 10,
              width: (isMobile || isMobileWeb) ? "100%" : null,
            }}
          >
            <PrestoText color={theme.primary} fontStyle="600.bold">
              Zip {I18n.t("screen_messages.common.order")} {item?.status}
              {` ${moment(order_date).format("YYYY-MM-DD")} `}
              <PrestoText size={15} color={theme.primary}>{`(${order_no})`}</PrestoText>
            </PrestoText>
            {!isTabAndAbove && (
              <PrestoText color={theme.primary} fontStyle="600.bold">
                {item?.status}
              </PrestoText>
            )}
          </View>
        </View >
        {
          _.map(item.items, (itemObj, index) => {
            const item = itemObj;
            const name = item.name;
            const molecules = item?.other_data?.molecule;
            const quantity = item?.quantity;
            let totalPriceFormatted = utils.formattedPriceWithoutCurrency(
              item.total_price / 100
            );
            let totalRateFormatted = utils.formattedPriceWithoutCurrency(
              item.rate / 100
            );
            const itemPurchaseConfiguration = {
              sale_to_purchase_ratio: item?.other_data["sale to purchase ratio"],
              sale_unit_display_name: item?.other_data["sale unit"],
              purchase_unit_display_name: item?.other_data["purchase unit"],
            };
            if (isMobile) {
              const product = new ItemCardModel({ product: item });
              return (
                <ItemCard
                  key={product?.product?.id}
                  product={product}
                  forPurchases={true}
                  onPress={() => { }}
                  value={quantity}
                  hideButtons={true}
                  selected={isActive || isAddedToBucket}
                  isZipSupplied={isZipSupplied(item)}
                  zipImage={images.ZipIcon}
                ></ItemCard>
              );
            } else {
              return (
                <Pressable
                  key={`${item.id}-${index}`}
                  onPress={() => onPressRow(item)}
                  style={{
                    width: "100%",
                    backgroundColor: bgColor,
                    borderRadius: 12,
                    paddingHorizontal: theme.primaryPadding,
                    paddingVertical: 10,
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      width: "33%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 10,
                    }}
                  >
                    <PrestoText color={textColor2} fontStyle="400.normal">
                      {name}
                    </PrestoText>

                    <PrestoText
                      size={10}
                      color={textColor4}
                      fontStyle="400.normal"
                    >
                      {molecules}
                    </PrestoText>
                  </View>

                  <View
                    style={{
                      width: "12%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ textAlign: "right" }}
                      color={textColor4}
                      fontStyle="600.semibold"
                    >
                      {item?.sourceItem?.stock_count}
                    </PrestoText>
                    <PrestoText
                      size={10}
                      color={textColor4}
                      fontStyle="400.normal"
                    >
                      {itemPurchaseConfiguration?.sale_unit_display_name}
                    </PrestoText>
                  </View>

                  <View
                    style={{
                      width: "12%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 0,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ textAlign: "center" }}
                      color={textColor4}
                      fontStyle="600.semibold"
                    ></PrestoText>
                  </View>

                  <View
                    style={{
                      width: "12%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 0,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ textAlign: "center" }}
                      color={textColor4}
                      fontStyle="600.semibold"
                    >
                      {itemPurchaseConfiguration?.purchase_unit_display_name}
                    </PrestoText>
                    <PrestoText
                      size={10}
                      color={textColor4}
                      fontStyle="400.normal"
                    >
                      {I18n.t("screen_messages.common.contains")}{" "}
                      {itemPurchaseConfiguration?.sale_to_purchase_ratio}{" "}
                      {itemPurchaseConfiguration?.sale_unit_display_name}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ textAlign: "right" }}
                      color={textColor4}
                      fontStyle="600.semibold"
                    >
                      {quantity}
                    </PrestoText>
                  </View>

                  <View
                    style={{
                      width: "13%",
                      flexDirection: "column",
                      borderRightWidth: 1,
                      borderRightColor: "#DBDBDB",
                      marginRight: 0,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ textAlign: "center" }}
                      color={textColor4}
                      fontStyle="600.semibold"
                    >
                      {item.total_price ? item.total_price / 100 : item.total_price}
                    </PrestoText >
                    {/* <PrestoText
                    size={10}
                    color={textColor4}
                    fontStyle="400.normal"
                  >
                    {itemPurchaseConfiguration?.sale_unit_display_name}
                  </PrestoText> */}
                  </View >
                </Pressable >
              );
            }
          })
        }
      </>
    );
  };

  const validate = (item) => {
    let isValid = true;
    let errorMessage = "";
    if (item.items) {
      _.forEach(item.items, (indItem) => {
        console.log(indItem.expiry, "fkljasiojsofdjiosdjfioajiosdjf")
        indItem.expiry = indItem.expiry || itemExpiryMap[indItem.id];
        if (!indItem.expiry) {
          isValid = false;
          errorMessage = I18n.t(
            "error_messages.products.please_enter_expiry_date_all"
          );
        }
      });
    } else {
      item.expiry = item.expiry || itemExpiryMap[item.id];
      console.log(JSON.stringify(item), "Dfakljsdfiajsdfoijs")
      if (!item.expiry) {
        isValid = false;
        errorMessage = I18n.t(
          "error_messages.products.please_enter_expiry_date_all"
        );
      }
    }
    if (!isValid) {
      alert(errorMessage);
    }
    return isValid
  }
  const onAddToInventory = ({ item }) => {
    let isValid = true;
    let errorMessage = "";
    _.forEach(item.items, (indItem) => {
      indItem.expiry = indItem.expiry || itemExpiryMap[indItem.id];
      if (!indItem.expiry) {
        isValid = false;
        errorMessage = I18n.t(
          "error_messages.products.please_enter_expiry_date_all"
        );
      }
    });
    if (!isValid) {
      alert(errorMessage);
      return;
    }
    console.log("Will inward all");
    console.log(JSON.stringify(item));
    let promises = _.map(item.items, (tempItem) => {
      console.log(tempItem);
      console.log("----");
      let params = {
        id: item.id,
        purchase_order: {
          line_item_id: tempItem.id,
          delivered_quantity: parseInt(tempItem.purchase_quantity),
          batch_number: tempItem.batch_number || "NO_BATCH",
          expiry_date: tempItem.expiry,
        },
      };

      let other_data = tempItem.other_data;
      if (other_data.avg_purchase_price && tempItem.total_price) {
        let avgpp =
          (parseFloat(other_data.avg_purchase_price) +
            parseFloat(tempItem.purchase_price_value) /
            (parseInt(tempItem.purchase_quantity) *
              parseInt(tempItem.other_data["sale to purchase ratio"]))) /
          2;
        other_data.avg_purchase_price = avgpp.toFixed(2);
      } else {
        let avgpp =
          parseFloat(tempItem.purchase_price_value) /
          (parseInt(tempItem.purchase_quantity) *
            parseInt(tempItem.other_data["sale to purchase ratio"]));
        other_data.avg_purchase_price = avgpp.toFixed(2);
      }
      if (tempItem.expiry) {
        other_data.nearest_expiry = moment(tempItem.expiry).format(
          "YYYY-MM-DD"
        );
      }
      console.log("Update");
      other_data.last_purchase_price = (
        parseFloat(tempItem.purchase_price_value) /
        (parseInt(tempItem.purchase_quantity) *
          parseInt(tempItem.other_data["sale to purchase ratio"]))
      ).toFixed(2);
      delete other_data["merchant_data"];
      let params1 = {
        category_item: {
          is_available: true,
          name: tempItem.name,
          other_data: JSON.stringify(other_data),
        },
        price: {
          currency: Config.merchant.currency || "ZMW",
        },
      };
      let params2 = {};
      params2.id = tempItem.item_id;
      params2.body = params1;
      console.log(JSON.stringify(params2));
      console.log("Update ==== ");
      console.log(params);
      EmployeeManager.updateCategoryItem(
        params2,
        (res) => {
          console.log("Result");
          console.log(res);
        },
        (err) => {
          console.log(err);
          console.log("Error In Update");
        }
      );

      return AsyncPurchaseOrdersManager.updateDeliveredQuantity(params).then(
        async ([error, response]) => {
          console.log("Update PO -- Error -- ");
          console.log(error);
          console.log(response);

          if (error) {
            return error;
          }
          let itemIds = await AsyncStorage.getItem("ITEM_IDS");
          if (!itemIds) {
            itemIds = "[]";
          }
          let ids = JSON.parse(itemIds);
          if (ids.indexOf(tempItem.item_id) == -1) {
            ids.push(tempItem.item_id);
            await AsyncStorage.setItem("ITEM_IDS", JSON.stringify(ids));
          }

          let zitemIds = await AsyncStorage.getItem("ZIP_ITEM_IDS");
          if (!zitemIds) {
            zitemIds = "[]";
          }
          let zids = JSON.parse(zitemIds);
          zids.splice(zids.indexOf(tempItem.item_id), 1);
          console.log(zids);
          console.log(tempItem.item_id);
          console.log("=======");
          await AsyncStorage.setItem("ZIP_ITEM_IDS", JSON.stringify(zids));

          console.log(zids);
          console.log("=======");

          return response.data;
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        purchaseOrderObject
          .markAsDelivered(item)
          .then(({ success }) => {
            console.log("I came here");
            if (success) {
              alertBox(I18n.t("success_messages.products.purchase_order"));
              loadPurchaseOrders();
              setTimeout(function () {
                const offlineSync = new OfflineSync(user);
                const force = true;
                offlineSync.syncCatalog(force);
                console.log("Sync in progress");
              }, 20000);
            } else {
              alertBox(I18n.t("error_messages.products.failed_to_move"));
            }
          })
          .catch((e) => { });
      })
      .catch((error) => {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        return alertBox("", message);
      });
  };

  const onSelectZipItem = ({ item, purchaseOrder }) => {
    console.log("Selected Item");
    console.log(item);
    console.log(item.batch_number);

    let params = {
      id: purchaseOrder.id,
      purchase_order: {
        line_item_id: item.id,
        delivered_quantity: parseInt(item.purchase_quantity),
        batch_number: item.batch_number || "NO_BATCH",
        expiry_date: item.expiry,
      },
    };
    console.log(params);
    AsyncPurchaseOrdersManager.updateDeliveredQuantity(params).then(
      ([error, response]) => {
        console.log(error);
        console.log(response);
        if (error) {
          alert(error.message);
        }
      }
    );
  };

  const onBatchNumberChange = function (item, batch_number) {
    console.log(batch_number);
    console.log(item.batch_number);
    console.log("-------");
    // item.item.batch_number = item.batch_number;
  };

  const onPurchasePriceChange = function (item, purchase_price) { };

  const onPurchaseQuantityChange = function (item, purchase_quantity) { };

  const onExpiryDateChange = function (item, value) { };

  const renderIncomingItem = ({ item, index }) => {
    const purchaseOrder = item;
    const isActive = false;
    const isAddedToBucket = false;
    let bgColor = isActive ? "#6D707B" : theme.white;
    if (isAddedToBucket) {
      bgColor = theme.primary;
    }

    const textColor1 =
      isActive || isAddedToBucket ? theme.white : theme.primary;
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor3 = isActive || isAddedToBucket ? theme.white : theme.black;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";
    const zipOrderStatus = item?.zipOrder?.status;
    if (
      zipOrderStatus == "RECEIVED" ||
      zipOrderStatus == "ACCEPTED" ||
      zipOrderStatus == "DISPATCHED" ||
      zipOrderStatus == "DELIVERED" ||
      (showClosed && zipOrderStatus == "CLOSED")
    ) {
      return (
        <>
          <View
            style={{
              paddingTop: 10,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexGrow: 1,
                flexDirection: "column",
                borderRightWidth: 1,
                borderRightColor: "#DBDBDB",
                padding: 10,
              }}
            >
              <PrestoText color={theme.primary} fontStyle="600.bold">
                Zip {I18n.t("screen_messages.common.order")}{" "}
                {isTabAndAbove
                  ? ` - {${item?.zipOrder?.friendly_id} (${item?.zipOrder?.status})}`
                  : " : "}
              </PrestoText>
              {!isTabAndAbove && (
                <PrestoText color={theme.primary} fontStyle="600.bold">
                  {item?.zipOrder?.friendly_id} ({item?.zipOrder?.status})
                </PrestoText>
              )}
            </View>
            <View
              style={{
                width: 120,
                marginBottom: 0,
              }}
            >
              <PrestoSolidButton
                size="small"
                buttonStyle="secondary"
                titleColor={theme.white}
                title={I18n.t("screen_messages.common.confirm_order")}
                paddingLeft="20"
                titleExtraStyle={{ textTransform: "capitalize" }}
                onPress={() => {
                  let status = item?.zipOrder?.status;
                  if (zipB2bOrders) {
                    if (
                      status == "DELIVERED" ||
                      status == "DISPATCHED" ||
                      status == "CLOSED"
                    ) {
                      zipB2bOrders(item, validate)
                    } else {
                      alert(I18n.t("screen_messages.orders.inward_orders"));
                    }
                    return;
                  }
                  if (
                    status == "DELIVERED" ||
                    status == "DISPATCHED" ||
                    status == "CLOSED"
                  ) {
                    onAddToInventory({ item });
                  } else {
                    alert(I18n.t("screen_messages.orders.inward_orders"));
                  }
                }}
              />
            </View>
          </View>
          {_.map(item.items, (itemObj, index) => {
            const item = itemObj;
            const name = item.name;
            const molecules = item?.other_data?.molecule;
            const quantity = item?.quantity;
            let totalPriceFormatted = utils.formattedPriceWithoutCurrency(
              item.total_price / 100
            );
            let totalRateFormatted = utils.formattedPriceWithoutCurrency(
              item.rate / 100
            );
            const itemPurchaseConfiguration = {
              sale_to_purchase_ratio:
                item?.other_data["sale to purchase ratio"],
              sale_unit_display_name: item?.other_data["sale unit"],
              purchase_unit_display_name: item?.other_data["purchase unit"],
            };

            if (item.expiry_date) {
              item.expiry = item.expiry_date
            }

            const onPressRowMobile = (item, purchaseOrder) => {
              console.log("lkdjsafisjdfoj")
              Navigator.emit({
                event: "modal",
                params: {
                  screenName: "NON_ZIP_PURCHASES",
                  screenParams: {
                    item: item,
                    setExpiryDate: true,
                    buttonTitle: "Update",
                    onDateChange: onDateChange,
                    goBack: () => {
                      Navigator.emit({ event: "goBack" });
                    },
                    onBatchNumberChange: (batch_number) => {
                      item.batch_number = batch_number;
                    },
                    onPurchasePriceChange: (purchase_price) => {
                      item.purchase_price_value = purchase_price;
                    },
                    onPurchaseTaxChange: (purchase_tax_value) => {
                      item.purchase_tax_value = purchase_tax_value;
                    },
                    onPurchaseQuantityChange: (purchase_quantity) => {
                      item.purchase_quantity = purchase_quantity;
                    },
                    onPressButton: () => {
                      Navigator.emit({ event: "goBack" });
                      if (!zipB2bOrders) {
                        onSelectZipItem({ item, purchaseOrder });
                      }
                    },
                  },
                },
              });
            };

            const onDateChange = (value) => {
              itemExpiryMap[item.id] = value;
              item.expiry = value;
            };

            if (isMobile || isMobileWeb) {
              return (
                <ItemCard
                  key={`${item.id}-${index}`}
                  product={item}
                  forMasterCatalog={true}
                  forOrders={true}
                  value={quantity}
                  onPress={() => {
                    onPressRow(item);
                    onPressRowMobile(item, purchaseOrder);
                    // onSelectZipItem({ item, purchaseOrder });
                  }}
                  selected={isActive || isAddedToBucket}
                  zipImage={images.ZipIcon}
                ></ItemCard>
              );
            }
            return (
              <Pressable
                key={`${item.id}-${index}`}
                onPress={() => onPressRow(item)}
                style={{
                  width: "100%",
                  backgroundColor: bgColor,
                  borderRadius: 12,
                  paddingHorizontal: theme.primaryPadding,
                  paddingVertical: 10,
                  flexDirection: "row",
                  marginBottom: 5,
                }}
              >
                <View
                  style={{
                    width: "22%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 10,
                    paddingRight: 10,
                  }}
                >
                  <PrestoText color={textColor2} fontStyle="400.normal">
                    {name}
                  </PrestoText>

                  <PrestoText
                    size={10}
                    color={textColor4}
                    fontStyle="400.normal"
                  >
                    {molecules}
                  </PrestoText>
                </View>

                <View
                  style={{
                    width: "16%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: 20,
                    marginRight: 0,
                  }}
                >
                  {
                    isWeb
                      ? <YearMonthSelect
                        onDateChange={(value) => {
                          itemExpiryMap[item.id] = value;
                          item.expiry = value;
                          console.log(itemExpiryMap);
                        }}
                      ></YearMonthSelect>
                      :
                      <YearMonthPicker
                        onDateChange={(value) => {
                          itemExpiryMap[item.id] = value;
                          item.expiry = value;
                          console.log(itemExpiryMap);
                        }}
                      ></YearMonthPicker>
                  }
                </View>

                <View
                  style={{
                    width: "13%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <PrestoTextInput
                    value={item.batch_number}
                    clearTextOnFocus={false}
                    onChange={({ nativeEvent: { text } }) => {
                      let batch_number = text;
                      item.batch_number = text;
                    }}
                    style={{
                      textInputHeight: 40,
                      textInputWidth: "100%",
                      borderRadius: 6,
                      marginLeft: 10,
                    }}
                    backgroundColor={theme.white}
                    theme={theme}
                    placeholder=""
                    textInputStyle={{
                      width: 80,
                      borderWidth: 1,
                      borderRadius: 4,
                      paddingLeft: 10,
                      paddingVertical: 5,
                      borderColor: "#aaaaaa",
                      backgroundColor: "#ffffff",
                    }}
                  ></PrestoTextInput>
                </View>
                <View
                  style={{
                    width: "12%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoAmountInput
                    value={item.purchase_price_value}
                    clearTextOnFocus={false}
                    onAmountChange={(purchase_price) => {
                      onPurchasePriceChange({ item, purchase_price })
                      if (zipB2bOrderItems) {
                        item.purchase_price_value = purchase_price
                      }
                    }
                    }
                    placeholder=""
                    keyboardType="numeric"
                    textInputStyle={{
                      width: 100,
                      borderWidth: 1,
                      borderRadius: 4,
                      paddingLeft: 10,
                      paddingVertical: 5,
                      borderColor: "#aaaaaa",
                      backgroundColor: "#ffffff",
                    }}
                  ></PrestoAmountInput>
                </View>

                <View
                  style={{
                    width: "6%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoAmountInput
                    value={item.purchase_tax_value}
                    clearTextOnFocus={false}
                    onAmountChange={(purchase_tax_value) => {
                      onPurchasePriceChange({ item, purchase_tax_value })
                      if (zipB2bOrderItems) {
                        item.purchase_price_value = purchase_tax_value
                      }
                    }
                    }
                    placeholder=""
                    keyboardType="numeric"
                    textInputStyle={{
                      width: 60,
                      borderWidth: 1,
                      borderRadius: 4,
                      paddingLeft: 10,
                      paddingVertical: 5,
                      borderColor: "#aaaaaa",
                      backgroundColor: "#ffffff",
                    }}
                  ></PrestoAmountInput>
                </View>
                <View
                  style={{
                    width: "8%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 0,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoText
                    extraStyle={{ textAlign: "center" }}
                    color={textColor4}
                    fontStyle="600.semibold"
                  >
                    {quantity}
                  </PrestoText>
                </View>

                <View
                  style={{
                    width: "9%",
                    flexDirection: "column",
                    borderRightWidth: 1,
                    borderRightColor: "#DBDBDB",
                    marginRight: 0,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoAmountInput
                    value={item.purchase_quantity}
                    clearTextOnFocus={false}
                    onAmountChange={(purchase_quantity) =>
                      (item.purchase_quantity = purchase_quantity)
                    }
                    placeholder=""
                    keyboardType="numeric"
                  ></PrestoAmountInput>
                </View>

                <View
                  style={{
                    // width: "11%",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignContent: "center",
                    alignItems: "center",
                    paddingHorizontal: 5,
                  }}
                >
                  <PrestoFramedButton
                    size="small"
                    buttonStyle="primary"
                    title={I18n.t("screen_messages.common.confirm_item")}
                    titleColor={isAddedToBucket ? theme.white : theme.primary}
                    titleFontStyle={"400.normal"}
                    titleSize={11}
                    height={29}
                    extraStyle={{ paddingHorizontal: 6 }}
                    // width={isAddedToBucket ? 110 : 110}
                    borderRadius={6}
                    borderColor={isAddedToBucket ? theme.white : theme.primary}
                    onPress={() => {
                      if (
                        zipOrderStatus == "DELIVERED" ||
                        zipOrderStatus == "DISPATCHED" ||
                        zipOrderStatus == "CLOSED"
                      ) {
                        //onPressActionButton({ item, itemPurchaseRequest })
                        if (zipB2bOrderItems) {
                          zipB2bOrderItems(item, validate)
                          return;
                        }
                      }
                      if (
                        zipOrderStatus == "DELIVERED" ||
                        zipOrderStatus == "DISPATCHED" ||
                        zipOrderStatus == "CLOSED"
                      ) {
                        onSelectZipItem({ item, purchaseOrder });
                      } else {
                        alert(
                          I18n.t("screen_messages.orders.zip_order_delivered")
                        );
                      }
                    }}
                  />
                </View>
              </Pressable>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderItem1 = ({ item, index }) => {
    let noOfItems = getTotalDeliveredQty(item);
    let totalPriceFormatted = getTotalPrice(item);
    let type = item.type;
    let status = item.status;
    let statusFormatted = I18n.t(
      `screen_messages.purchase_requests.status.${_.toLower(status)}`
    );
    let typeFormatted = I18n.t(
      `screen_messages.purchase_requests.types.${_.toLower(type)}`
    );
    let createdAt = item.created_at;
    let createdAtDate = moment(createdAt);
    let orderedBy = item.ordered_by_hash;

    const isActive = _.get(activeRow, "id") === item.id;
    let bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;
    const textColor3 = isActive ? theme.white : "#B6B6B6";
    const textColor4 = isActive ? theme.white : "#666666";

    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 12,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "15%",
            flexDirection: "column",
          }}
        >
          <View>
            <PrestoText
              extraStyle={{}}
              color={textColor1}
              fontStyle="600.semibold"
            >
              {createdAtDate.format("DD-MM-YYYY")}
            </PrestoText>
            <PrestoText
              extraStyle={{}}
              color={textColor2}
              fontStyle="600.semibold"
            >
              {orderedBy.name}
            </PrestoText>
          </View>
        </View>

        <View
          style={{
            width: "55%",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            paddingRight: 10,
          }}
        >
          <View>
            <PrestoText
              extraStyle={{}}
              color={textColor2}
              fontStyle="400.normal"
            >
              {getSupplierById(item.supplier_id)?.name ||
                I18n.t("screen_messages.suppliers.to_be_assigned")}
            </PrestoText>
          </View>

          <View>
            <PrestoText size={11} color={textColor3} fontStyle="400.normal">
              {getItemsCountText(item)}
            </PrestoText>
          </View>
        </View>

        <View
          style={{
            width: "15%",
            flexDirection: "row",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor4}
            fontStyle="600.semibold"
          >
            {typeFormatted}
          </PrestoText>
        </View>

        <View
          style={{
            width: "15%",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: theme.primaryPadding,
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor1}
            fontStyle="600.semibold"
          >
            {totalPriceFormatted}
          </PrestoText>
        </View>
      </Pressable>
    );
  };
  return (
    <View>
      {isIncomingScreen ? (
        <View style={{
          paddingLeft: 10, paddingRight: 10, paddingBottom: 60, height: !isWeb ? "100%" : "90vh"
        }}>
          <FlatList
            stickyHeaderIndices={[0]}
            keyExtractor={(item, index) => `${item.id}-${index}`}
            data={items}
            renderItem={renderIncomingItem}
            ListHeaderComponent={() => {
              if (isMobile || isMobileWeb) return;
              return <RenderIncomingItemHeader />;
            }}
            onEndReached={onEndReached}
          />
        </View>
      ) : (
        <View style={{ ...(isWeb && isDesktop) && { height: "90vh" } }}>
          <FlatList
            stickyHeaderIndices={[0]}
            keyExtractor={(item, index) => `${item.id}-${index}`}
            data={items}
            renderItem={renderItem}
            ListHeaderComponent={() => {
              if (isMobile || isMobileWeb) return;
              return <RenderItemHeader />;
            }}
            onEndReached={onEndReached}
          />
        </View>
      )}
    </View>
  );
}
