import React, { useEffect, useState, useContext, useMemo } from "react";
import { View, ScrollView } from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { alertBox, prestoAlert } from "@presto-common/Alert";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import TextInputLargeBox from "@presto-components/TextInputs/TextInputLargeBox";
import PickerMobile from "@presto-components/PrestoPickers/PickerMobile.buadmin";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import I18n from "i18n-js";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import asyncify from "@presto-common/Asyncify";
import utils from "../../utils";
import SearchManager from "@presto-services/features/search/SearchManager";
import UserContext from "@presto-contexts/UserContext";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";

const AsyncEmployeeManager = asyncify(EmployeeManager);

export default function ProductForm({
  suppliers,
  catalog,
  product,
  searchQuery,
  dispatch,
}) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();
  let therapyAreas = [
    {
      label: "Anti-Infections for Systemic Use",
      value: "Anti-Infections for Systemic Use",
    },
    { label: "Vitamins and Minerals", value: "Vitamins and Minerals" },
    { label: "Other", value: "Other" },
    {
      label: "Analgesics and Anti-Inflammatories",
      value: "Analgesics and Anti-Inflammatories",
    },
    { label: "Diabetes", value: "Diabetes" },
    { label: "Nervous system", value: "Nervous system" },
    { label: "Cardiovascular", value: "Cardiovascular" },
    { label: "Gastroenterology", value: "Gastroenterology" },
    { label: "Dermatology", value: "Dermatology" },
    {
      label: "Respiratory, Cough and Cold",
      value: "Respiratory, Cough and Cold",
    },
    {
      label: "Blood and Hematinic Disorders",
      value: "Blood and Hematinic Disorders",
    },
    {
      label: "Antiparasitic Products, Insecticides and Repellents",
      value: "Antiparasitic Products, Insecticides and Repellents",
    },
    { label: "Hormones", value: "Hormones" },
    { label: "Genito-Urinary", value: "Genito-Urinary" },
    { label: "Musculoskeletal System", value: "Musculoskeletal System" },
    { label: "Sensory organs", value: "Sensory organs" },
  ];
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    listingOrder: "",
    description: "",
    price: "",
    referenceId: "",
    stockCount: "",
    saleUnitDisplayName: "",
    purchaseUnitDisplayName: "",
    saleToPurchaseRatio: "",
    supplierPrices: [
      {
        supplier: null,
        price: "",
      },
    ],
  });
  const [formSteps, setFormSteps] = useState({
    details: true,
    purchaseConfiguration: false,
    supplierPrices: false,
    isReferenceId: false,
  });

  const fetchCategories = function () {
    if (categoryTypes.length == 0) {
      let categories = [];
      var params = {
        merchant_id: user.merchant_id,
      };
      SearchManager.searchCategory(
        params,
        (response) => {
          console.log("Categories");
          response.hits.hits.forEach((item) => {
            if (item._source.category_id) {
              categories.push({
                label: item._source.name,
                value: item._source.id,
                catalog_id: item._source.catalog_id,
              });
            }
          });
          setCategoryTypes(categories);
          console.log(categories);
        },
        (error) => { }
      );
    }
  };
  fetchCategories();
  const memoizedSupplierChoices = useMemo(() => {
    let supplierIds = _.compact(
      _.map(form.supplierPrices, (e) => _.get(e, "supplier.value"))
    );

    let tempSuppliers = _.cloneDeep(suppliers);

    tempSuppliers = _.compact(
      _.map(tempSuppliers, (e) => {
        return !_.includes(supplierIds, e.id)
          ? {
            value: e.id,
            label: e.name,
            reference_id: e.reference_id,
          }
          : null;
      })
    );

    return tempSuppliers;
  }, [form.supplierPrices]);

  useEffect(() => {
    if (product) {
      setFormData();
    } else if (searchQuery) {
      onSetForm("name", searchQuery);
    }
  }, [product, searchQuery]);

  const setFormData = () => {
    let tempForm = _.cloneDeep(form);
    tempForm.id = _.get(product, "id", null);
    tempForm.name = _.get(product, "name", "");
    tempForm.listingOrder = _.get(product, "listing_order", "").toString();
    tempForm.description = _.get(product, "description", "");
    tempForm.price = _.get(product, "price.base_price", "").toString();
    tempForm.referenceId = _.get(product, "reference_id", "");
    tempForm.stockCount = _.get(product, "stock_count", "").toString();
    tempForm.distributor = product?.other_data?.distributor
      ? _.get(product, "other_data.distributor", "").toString()
      : "";
    tempForm.manufacturer = _.get(product, "other_data.company_name", "");
    tempForm.molecule = product?.other_data?.molecule
      ? _.get(product, "other_data.molecule", "").toString()
      : "";

    if (product.other_data?.therapy_area_simple) {
      let tas = _.find(therapyAreas, [
        "value",
        product.other_data.therapy_area_simple,
      ]);
      if (tas) {
        tempForm.therapy = {
          value: tas.value,
          label: tas.label,
        };
      } else {
      }
    }

    if (product.category_id) {
      let category = _.find(categoryTypes, ["value", product.category_id]);
      if (category) {
        tempForm.category = {
          value: product.category_id,
          label: category.label,
        };
      } else {
      }
    }

    setForm((prev) => {
      return { ...prev, ...tempForm };
    });

    setFormSteps((prev) => {
      return {
        ...prev,
        details: true,
        purchaseConfiguration: true,
        supplierPrices: true,
        isReferenceId: tempForm.referenceId ? true : false
      };
    });

    getPurchaseConfiguration().then((purchaseConfiguration) => {
      if (purchaseConfiguration) {
        tempForm.purchaseConfigurationId = _.get(purchaseConfiguration, "id");
        tempForm.saleUnitDisplayName = _.get(
          purchaseConfiguration,
          "sale_unit_display_name"
        );
        tempForm.purchaseUnitDisplayName = _.get(
          purchaseConfiguration,
          "purchase_unit_display_name"
        );
        tempForm.saleToPurchaseRatio = _.get(
          purchaseConfiguration,
          "sale_to_purchase_ratio"
        ).toString();

        if (purchaseConfiguration.supplier_prices) {
          tempForm.supplierPrices = _.map(
            purchaseConfiguration.supplier_prices,
            (supplierPrice) => {
              return {
                id: supplierPrice.id,
                supplier: {
                  value: supplierPrice.supplier_id,
                  label: supplierPrice.name,
                },
                price: supplierPrice.purchase_price.toString(),
              };
            }
          );
        }

        setForm((prev) => {
          return { ...prev, ...tempForm };
        });
      }
    });
  };

  const onSetForm = (key, val) => {
    setForm({ ...form, [key]: val });
  };

  const getPurchaseConfiguration = () => {
    return AsyncEmployeeManager.itemPurchaseConfigurationShow({
      id: product.id,
    }).then(([error, response]) => {
      return response?.data || null;
    });
  };

  const onValidateOnSubmitDetails = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(form.name)) {
      error = I18n.t("screen_messages.stock.please_enter_name");
    } else if (_.isEmpty(form.price)) {
      error = I18n.t("screen_messages.stock.please_enter_price");
    } else if (_.isEmpty(form.stockCount)) {
      error = I18n.t("screen_messages.create_product.enter_stock");
    }
    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSubmitDetails = async () => {
    let { isValid, error } = onValidateOnSubmitDetails();
    if (!isValid) {
      return alertBox("", error);
    }

    let price = Number(parseFloat(form.price)) * 100;
    if (isNaN(price)) {
      price = 0;
    }
    var othData = {
      ...(product?.other_data || {}),
      ...{
        company_name: form.manufacturer,
        distributor: form.distributor,
        molecule: form.molecule,
        therapy_area_simple: form.therapy?.value,
        "sale unit": form.saleUnitDisplayName,
        "purchase unit": form.purchaseUnitDisplayName,
        "sale to purchase ratio": form.saleToPurchaseRatio,
      },
    };

    var computedData = JSON.stringify(othData);
    let params = {
      category_item: {
        name: form.name,
        description: form.description,
        listing_order: parseInt(form.listingOrder) || 4000,
        category_id: form.category?.value || product?.category_id,
        catalog_id: categoryTypes[0].catalog_id || catalog?.id,
        reference_id: form.referenceId,
        stock_count: form.stockCount,
        other_data: computedData,
        is_available: true,
      },
      price: {
        base_price: price,
      },
    };

    console.log(params);
    console.log("=====");
    let method = null;
    if (form.id) {
      method = AsyncEmployeeManager.updateCategoryItem;
      params.id = form.id;
      params.body = _.cloneDeep(params);
      params.body = _.omit(params.body, ["id"]);
      params = _.omit(params, ["category_item", "price"]);
    } else {
      method = AsyncEmployeeManager.createCategoryItem;
    }

    setLoading(true);
    const [error1, response] = await method(params);
    setLoading(false);
    if (error1) {
      console.log("ProductForm->onSubmitDetails->error", error1);
      alertBox("", error1.message);
    } else {
      CategoryDataSources.updatePrice({ id: product?.id, merchant_id: product?.merchant_id, price: price, stock_count: form.stockCount, enableOffline: true });
      if (form?.id) {
        dispatch({
          type: "SET_CURRENT_TAB",
          payload: "Store Inventory",
        });
      }
      onSetForm("id", response.data.id);
      if (!response.data.reference_id) {
        dispatch({
          type: "SET_CURRENT_TAB",
          payload: "Store Inventory",
        });
      }
      setFormSteps({
        ...formSteps,
        purchaseConfiguration: true,
        details: false,
        isReferenceId: response.data.reference_id ? true : false
      });

      let message = form.id
        ? I18n.t("success_messages.products.updated")
        : I18n.t("success_messages.products.created");

      alertBox("", message);
    }
  };

  const onValidateOnPurchaseConfiguration = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(form.saleUnitDisplayName)) {
      error = I18n.t(
        "error_messages.purchase_configurations.sale_unit_display_name_required"
      );
    } else if (_.isEmpty(form.purchaseUnitDisplayName)) {
      error = I18n.t(
        "error_messages.purchase_configurations.purchase_unit_display_name_required"
      );
    } else if (_.isEmpty(form.saleToPurchaseRatio)) {
      error = I18n.t(
        "error_messages.purchase_configurations.sale_to_purchase_ratio_required"
      );
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSubmitPurchaseConfiguration = async () => {
    let { isValid, error } = onValidateOnPurchaseConfiguration();
    if (!isValid) {
      return alertBox("", error);
    }

    let params = {
      purchase_configuration: {
        sale_unit_display_name: form.saleUnitDisplayName,
        purchase_unit_display_name: form.purchaseUnitDisplayName,
        sale_to_purchase_ratio: form.saleToPurchaseRatio,
        category_item_id: form.id,
      },
    };

    let method = null;
    if (form.purchaseConfigurationId) {
      method = AsyncEmployeeManager.purchaseConfigurationUpdate;
      params.id = form.purchaseConfigurationId;
    } else {
      method = AsyncEmployeeManager.purchaseConfigurationCreate;
    }

    setLoading(true);
    const [error1, response] = await method(params);
    setLoading(false);
    if (error1) {
      console.log("ProductForm->onSubmitPurchaseConfiguration->error", error1);
      alertBox("", error1.message);
    } else {
      onSetForm("purchaseConfigurationId", response.data.id);
      dispatch({
        type: "SET_CURRENT_TAB",
        payload: "Store Inventory",
      });
      setFormSteps({
        ...formSteps,
        purchaseConfiguration: true,
        details: true,
        supplierPrices: true,
      });

      let message = form.purchaseConfigurationId
        ? I18n.t("success_messages.purchase_configurations.updated")
        : I18n.t("success_messages.purchase_configurations.created");

      alertBox("", message);
    }
  };

  const onValidateSupplierPrices = () => {
    let isValid = true,
      error = null;

    let supplierPrices = _.cloneDeep(form.supplierPrices);

    if (supplierPrices.length === 0) {
      error = I18n.t("error_messages.suppliers.atleast_one_supplier_prices");
    } else if (_.isEmpty(form.purchaseConfigurationId)) {
      error = I18n.t("error_messages.purchase_configurations.not_present");
    } else {
      for (let supplierPrice of supplierPrices) {
        if (_.isEmpty(supplierPrice.supplier)) {
          error = I18n.t("error_messages.suppliers.supplier_required");
          break;
        }

        if (_.isEmpty(supplierPrice.price)) {
          error = I18n.t("error_messages.suppliers.supplier_price_required");
          break;
        }
      }
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSubmitSupplierPrices = async () => {
    let { isValid, error } = onValidateSupplierPrices();
    if (!isValid) {
      return alertBox("", error);
    }
    setLoading(true);
    let promises = _.map(form.supplierPrices, (supplierPrice, index) => {
      let params = {
        supplier_price: {
          purchase_price: supplierPrice.price,
        },
      };

      if (supplierPrice.id) {
        params.id = supplierPrice.id;
        params.purchase_configuration_id = form.purchaseConfigurationId;
      } else {
        params.supplier_price.purchase_configuration_id =
          form.purchaseConfigurationId;
        params.supplier_price.supplier_id = supplierPrice.supplier.value;
      }

      let method = supplierPrice.id
        ? AsyncEmployeeManager.supplierPricesUpdate
        : AsyncEmployeeManager.supplierPricesCreate;

      return method(params).then(([error, response]) => {
        if (error) {
          return error;
        }

        setForm((prev) => {
          let supplierPrices = _.cloneDeep(prev.supplierPrices);
          supplierPrices[index].id = response.data.id;
          return { ...prev, supplierPrices };
        });
        return response;
      });
    });

    const responses = await Promise.all(promises);
    let errors = _.filter(responses, (response) => _.has(response, "error"));
    let successCount = responses.length - errors.length;
    let msg = "";
    setLoading(false);

    if (errors.length) {
      console.log("ProductForm->onSubmitSupplierPrices->error", errors);
      msg = I18n.t("error_messages.suppliers.not_saved1", {
        count: errors.length,
      });
    } else if (successCount > 0) {
      msg = I18n.t("screen_messages.suppliers.saved1", {
        count: successCount,
      });
      setFormSteps({
        ...formSteps,
        purchaseConfiguration: true,
        details: true,
        supplierPrices: true,
      });
    }
    alertBox("", msg);
  };

  const removeSupplierPrice = (index) => {
    let supplierPrices = _.cloneDeep(form.supplierPrices);
    let supplierPrice = supplierPrices[index];

    if (supplierPrice.id) {
      setLoading(true);
      AsyncEmployeeManager.supplierPricesDelete({
        id: supplierPrice.id,
        purchase_configuration_id: form.purchaseConfigurationId,
      }).then(([error, response]) => {
        setLoading(false);
        if (response) {
          supplierPrices.splice(index, 1);
          onSetForm("supplierPrices", supplierPrices);

          let message = I18n.t("success_messages.supplier_prices.deleted");
          alertBox("", message);
        } else {
          alertBox("", error.message);
        }
      });
    } else {
      supplierPrices.splice(index, 1);
      onSetForm("supplierPrices", supplierPrices);
    }
  };

  const addSupplierRow = () => {
    let supplierPrices = _.cloneDeep(form.supplierPrices);
    supplierPrices.push({
      supplier: null,
      price: "",
    });
    onSetForm("supplierPrices", supplierPrices);
  };

  const renderLabel = (title) => {
    return (
      <PrestoText color={theme.white} fontStyle={"400.semibold"} size={14}>
        {title}
      </PrestoText>
    );
  };

  const renderTextInput = ({
    label,
    placeholder = "",
    value,
    onChange,
    editable = true,
  }) => {
    return (
      <View>
        {label ? (
          <View>
            {renderLabel(label)}
            <VerticalSpacing size={10} />
          </View>
        ) : null}

        <PrestoTextInput
          editable={editable}
          style={{}}
          width={"100%"}
          theme={theme}
          placeholder={placeholder}
          value={value}
          keyboardType={"default"}
          onChange={({ nativeEvent: { text } }) => onChange(text)}
          backgroundColor={theme.white}
          borderWidth={2}
        />
      </View>
    );
  };

  return (
    <LoadingContainer bgColor={theme.screenBackgroundColor} loading={loading}>
      <ScrollView
        contentContainerStyle={{
          backgroundColor: theme.createItemBgColor,
          borderRadius: 20,
          margin: 20,
          paddingVertical: 30,
          paddingHorizontal: 20,
        }}
      >
        <View
          style={{
            flexDirection: isMobile || isMobileWeb ? "column" : "row",
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            {renderTextInput({
              label: I18n.t("screen_messages.stock.name") + "*",
              placeholder: I18n.t("screen_messages.add_name"),
              value: form.name,
              onChange: (val) => onSetForm("name", val),
            })}

            <VerticalSpacing size={theme.containerPadding} />

            {renderTextInput({
              label: I18n.t("screen_messages.orders.unit_price") + "*",
              placeholder: I18n.t("screen_messages.orders.enter_unite_price"),
              value: form.price,
              onChange: (val) => onSetForm("price", val),
            })}

            <VerticalSpacing size={theme.containerPadding} />

            {renderTextInput({
              label: I18n.t("screen_messages.create_product.stock") + "*",
              placeholder: I18n.t("screen_messages.create_product.enter_stock"),
              value: form.stockCount,
              onChange: (val) => onSetForm("stockCount", val),
            })}

            <VerticalSpacing size={theme.containerPadding} />

            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {renderTextInput({
                  label: I18n.t("screen_messages.inventory.manufacturer"),
                  placeholder: "",
                  value: form.manufacturer,
                  onChange: (val) => onSetForm("manufacturer", val),
                })}
              </View>
            </View>
          </View>

          <View style={{ width: 25 }} />

          <View
            style={{ flex: 1, paddingTop: isMobile || isMobileWeb ? 10 : 0 }}
          >
            {renderLabel(I18n.t("screen_messages.category_str"))}
            <VerticalSpacing size={15} />
            <PickerMobile
              dropdownIconStyles={{ color: theme.white }}
              textContainerStyles={{ borderColor: theme.subHeader }}
              items={categoryTypes}
              textColor={theme.white}
              selectedItem={form.category}
              onSelect={(val) => onSetForm("category", val)}
            />

            <VerticalSpacing size={theme.containerPadding + 10} />
            {renderLabel(I18n.t("screen_messages.common.therapy"))}
            <VerticalSpacing size={15} />
            <PickerMobile
              dropdownIconStyles={{ color: theme.white }}
              textContainerStyles={{ borderColor: theme.subHeader }}
              items={therapyAreas.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              textColor={theme.white}
              selectedItem={form.therapy}
              onSelect={(val) => onSetForm("therapy", val)}
            />

            <VerticalSpacing size={theme.containerPadding + 10} />

            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {renderTextInput({
                  label: I18n.t("screen_messages.common.molecule"),
                  placeholder: "",
                  value: form.molecule,
                  onChange: (val) => onSetForm("molecule", val),
                })}
              </View>
            </View>
            <VerticalSpacing size={theme.containerPadding} />

            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {renderTextInput({
                  label: I18n.t("screen_messages.inventory.distributor"),
                  placeholder: "",
                  value: form.distributor,
                  onChange: (val) => onSetForm("distributor", val),
                })}
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <PrestoSolidButton
            size="large"
            buttonStyle="primary"
            title={
              form.id
                ? I18n.t("screen_messages.common.update")
                : I18n.t("screen_messages.common.create")
            }
            titleColor={theme.white}
            titleFontStyle={"600.semibold"}
            titleSize={22}
            height={50}
            width={100}
            extraStyle={{ marginTop: 10 }}
            borderRadius={10}
            onPress={onSubmitDetails}
            bgColor={theme.primary}
          />
        </View>

        <VerticalSpacing size={15} />
        <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />

        <View
          style={{
            opacity: formSteps.isReferenceId
              ? formSteps.purchaseConfiguration
                ? 1
                : 0.4
              : 0.4,
          }}
        >
          <View
            style={{
              marginVertical: theme.containerPadding,
            }}
          >
            <PrestoText
              color={theme.white}
              fontStyle={"600.semibold"}
              size={16}
            >
              {I18n.t("screen_messages.purchase_configurations.title")}
            </PrestoText>
          </View>

          <View
            style={{
              flexDirection: isMobile || isMobileWeb ? "column" : "row",
              flex: 1,
            }}
          >
            <View style={{ flex: 1 }}>
              {renderTextInput({
                label: I18n.t(
                  "screen_messages.purchase_configurations.sale_unit_display_name"
                ),
                placeholder: "",
                value: form.saleUnitDisplayName,
                onChange: (val) => onSetForm("saleUnitDisplayName", val),
                editable: formSteps.isReferenceId
                  ? formSteps.purchaseConfiguration
                  : false,
              })}
            </View>
            {(isMobile || isMobileWeb) && <VerticalSpacing size={25} />}
            <View style={{ width: theme.containerPadding }} />

            <View style={{ flex: 1 }}>
              {renderTextInput({
                label: I18n.t(
                  "screen_messages.purchase_configurations.purchase_unit_display_name"
                ),
                placeholder: "",
                value: form.purchaseUnitDisplayName,
                editable: formSteps.isReferenceId
                  ? formSteps.purchaseConfiguration
                  : false,
                onChange: (val) => onSetForm("purchaseUnitDisplayName", val),
              })}
            </View>
            {(isMobile || isMobileWeb) && <VerticalSpacing size={25} />}

            <View style={{ width: theme.containerPadding }} />

            <View style={{ flex: 2 }}>
              {form.saleUnitDisplayName && form.purchaseUnitDisplayName
                ? renderTextInput({
                  label: I18n.t(
                    "screen_messages.purchase_configurations.strip_in_box",
                    {
                      strip: form.saleUnitDisplayName,
                      box: form.purchaseUnitDisplayName,
                    }
                  ),
                  placeholder: "",
                  value: form.saleToPurchaseRatio,
                  editable: formSteps.purchaseConfiguration,
                  onChange: (val) => onSetForm("saleToPurchaseRatio", val),
                })
                : null}
            </View>
          </View>

          {(isMobile || isMobileWeb) && <VerticalSpacing size={25} />}

          <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              title={I18n.t("screen_messages.common.update")}
              titleColor={theme.white}
              titleFontStyle={"600.semibold"}
              titleSize={22}
              height={50}
              width={100}
              borderRadius={10}
              disable={
                formSteps.isReferenceId
                  ? !formSteps.purchaseConfiguration
                  : true
              }
              onPress={onSubmitPurchaseConfiguration}
              bgColor={theme.primary}
            />
          </View>
        </View>

        <VerticalSpacing size={100} />
      </ScrollView>
    </LoadingContainer>
  );
}
