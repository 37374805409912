import React, { useContext } from "react";
import { View, Keyboard } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import TextInputWithIcon from "@presto-components/TextInputs/TextInputWithIcon";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";

export default function AdvanceSearchBar({
  searchValue,
  onChange,
  onPressIcon,
  disableIcon = false,
  placeHolder = I18n.t("screen_messages.common.search_by1"),
  isMobile,
  isModal,
  onSubmitEditing,
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  let iconSize = isMobile ? 24 : 31;
  const closeIcon = <svgs.CloseIcon2 width={iconSize} height={iconSize} />;
  return (
    <TextInputWithIcon
      icon={
        !disableIcon && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                Keyboard.dismiss();
                onChange("");
                onPressIcon();
              }}
            >
              <View
                style={{
                  paddingTop: 0,
                  paddingRight: 0,
                }}
              >
                <PrestoIcon icon={closeIcon} />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={onPressIcon}>
              <View
                style={{
                  width: isModal ? "80%" : 118,
                  height: 36,
                  backgroundColor: theme.primary,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PrestoText
                  extraStyle={{}}
                  color={theme.white}
                  fontStyle={"500.medium"}
                  size={16}
                >
                  {I18n.t("screen_messages.common.search")}
                </PrestoText>
              </View>
            </TouchableOpacity>
          </View>
        )
      }
      onChange={({ nativeEvent: { text } }) => onChange(text)}
      // onPressIcon={onPressIcon}
      showSoftInputOnFocus={true}
      value={searchValue}
      keyboardType="default"
      placeholder={placeHolder}
      maxLength={200}
      multiline={false}
      secureTextEntry={false}
      theme={{
        textInputBorderWidth: 2,
        overflow: "hidden",
        placeHolderColor: "#A9A9A9",
      }}
      textInputStyles={{
        justifyContent: "space-between",
        width: "100%",
      }}
      textInputStyle={{
        color: theme.black,
        fontSize: 14,
      }}
      textInputHeight={36}
      iconContainerStyles={{
        right: -13,
      }}
      onSubmitEditing={onSubmitEditing}
      onCancle={() => {
        Keyboard.dismiss();
        onChange("");
        onPressIcon();
      }}
    />
  );
}
