import config from "@presto-common/config";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import ThemeContext from "@presto-contexts/ThemeContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import MessagesContext from "@presto-contexts/MessagesContext";
import * as Linking from "expo-linking";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { getPosFeatureData } from "@presto-constants/FeatureConstants";
import SessionManager from "@presto-services/features/session/SessionManager";
import PrestoIcon from "@presto-components/PrestoIcon";
import I18n from "i18n-js";
import _ from "lodash";
import {
  Dimensions,
  Image,
  ScrollView,
  View,
  Pressable,
  FlatList,
  Platform,
  Switch,
  Text,
  Button,
} from "react-native";
import { useAssets } from "presto-react-components";
import CartContext from "@presto-contexts/CartContext";
// const RCTNetworking = require("react-native/Libraries/Network/RCTNetworking");
import UserContext from "@presto-contexts/UserContext";
import NetworkContext from "@presto-contexts/NetworkContext";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import BrandLogoSection from "@presto-screen-components/BrandLogo/BrandLogoSection";
import CartDataSources from "@presto-datasources/CartDataSources";
import { useFocusEffect } from "@react-navigation/native";
import LoginHelper from "@presto-helpers/LoginHelper";
import AsyncStorage from "@react-native-community/async-storage";
import { OfflineSync } from "@presto-helpers/OfflineSync";
import utils from "../../utils/index";
import Config, { updateConfig } from "@presto-common/config";
import CatalogContext from "@presto-contexts/CatalogContext";

export default function FeatureSelection() {
  const { images: Images, svgs } = useAssets();
  const isWeb = Platform.OS == 'web'
  const windowHeight = Dimensions.get("window").height;
  const { theme } = useContext(ThemeContext);
  const { networkInfo } = !isWeb ? useContext(NetworkContext) : { networkInfo };
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const { setUser, setCurrentAddress, user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const styles = getStyles(theme, windowHeight);
  const hideNetworkToggle = true;
  const [padding, setPadding] = useState(0);
  const scrollRef = useRef();
  const [featureActions, setFeatureActions] = useState({});
  const isDesktop = utils.isDesktop();
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();
  const maxColumns = (isMobile || isMobileWeb) ? 2 : 4;
  const { cart, refreshCart, currentCartData, multiCarts } = useContext(CartContext);
  console.log(multiCarts?.length, "aidsfaiojsdfjklajdkfaklsdjfl")

  const { isSync, setIsSync } = useContext(CatalogContext);

  const { locale, setLanguage } = useContext(MessagesContext);
  const [language, setScreenLanguage] = useState(locale);
  useEffect(() => {
    setScreenLanguage(locale);
  }, [locale]);

  const toggleNetwork = () => {
    if (locale === "en-US") {
      setLanguage("en-Portugese");
    } else {
      setLanguage("en-US");
    }
  };

  const merchantId = LoginHelper.getUserMerchantId(user);
  AsyncStorage.setItem("MERCHANT_ID", merchantId);

  useFocusEffect(
    useCallback(() => {
      fetchCarts();
    }, [cart])
  );

  useEffect(() => {
    let options = {
      ...Config, merchant: { currency: user?.other_data?.permissions?.currency }
    }
    updateConfig(options)
  }, [])

  useEffect(() => {
    // scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);

  const fetchCarts = () => {
    const onSuccess = (response) => {
      setFeatureActions((prev) => {
        return {
          ...prev,
          pos_management: {
            ...prev?.pos_management,
            openCarts: response?.data?.length,
          },
        };
      });
    };
    const onError = (error) => {
      console.log("FeatureSelection->fetchCarts->error", error);
    };
    if (isWeb) {
      onSuccess({ data: multiCarts })
    } else {
      CartDataSources.fetchCarts({}, onSuccess, onError);
    }
  };

  const hasPermissionToAccess = (key) =>
    _.get(user, `other_data.permissions.${key}`) === true;

  const onPress = (option) => {
    let option2 = _.omit(option, "icon");
    if (
      _.includes(
        [
          "pos_management",
          "reports_management",
          "sales_reports_management",
          "return_management",
          "customer_management",
          "purchase_request_management",
          "purchase_order_management",
          "admin_management",
        ],
        option?.state
      )
    ) {
      if (isWeb && isDesktop) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "HOME",
            screenParams: {
              selectedFeature: option2,
            },
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "HOME",
            screenParams: {
              selectedFeature: option2,
            },
          },
        })
      }
    } else if (option?.state === "all_stock") {
      if (isWeb && isDesktop) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "ALL_STOCK",
            screenParams: {
              selectedFeature: option2,
            },
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "ALL_STOCK",
            screenParams: {
              selectedFeature: option2,
            },
          },
        });
      }
    } else if (option?.state === "sales_management") {
      if (isWeb) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "CATALOG",
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "CATALOG",
          },
        });
      }
    } else if (option?.state === "purchase_management") {
      if (isWeb) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "ALL_SALES",
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "ALL_SALES",
          },
        })
      };
    } else if (option?.state === "insights_reports_management") {
      if (isWeb) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "INSIGHTS",
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "INSIGHTS",
          },
        })
      };
    } else if (option?.state === "sales_reports_management") {
      if (isWeb) {
        WebNavigator.emit({
          event: "replace",
          params: {
            screenName: "REPORTS_HOME",
          },
        });
      } else {
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "REPORTS_HOME",
          },
        })
      };
    } else if (option?.state === "sales_db_reports_management") {
      if (isWeb && isDesktop) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "SALESDB",
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "SALESDB",
          },
        });
      };
    } else if (option?.state === "offer_management") {
      if (isWeb && isDesktop) {
        WebNavigator.emit({
          event: "modal",
          params: {
            screenName: "OFFERS_SCREEN",
            screenParams: {
              selectedFeature: option2,
            },
          },
        });
      } else {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "OFFERS_SCREEN",
            screenParams: {
              selectedFeature: option2,
            },
          },
        })
      };
    } else {
      return;
    }
  };

  const resync = async () => {
    if (isSync) {
      return;
    }
    setLoading(true);
    const offlineSync = new OfflineSync(user);
    await offlineSync.syncAll(false);
    setLoading(false);
  };


  const onLogout = () => {
    setLoading(true);
    const metaData = new DBMetaDataManager();

    if (config?.user_type === "employee") {
      SessionManager.agentLogout(
        async (response) => {
          console.log("iosadfijsdfkljlksdjfkjlksdjf")
          await metaData.setCurrentLoggedUserInfo(null);
          if (!isWeb) RCTNetworking.clearCookies((result) => { });
          if (isWeb && isDesktop) {
            WebNavigator.emit({
              event: "replace",
              params: {
                screenName: "LOGIN",
              },
            });
          } else {
            Navigator.emit({
              event: "replace",
              params: {
                screenName: "LOGIN",
              },
            });
          }
          setUser(undefined);
          setCurrentAddress(null);
          setLoading(false);
        },
        async (error) => {
          await metaData.setCurrentLoggedUserInfo(null);
          setLoading(false);
          //console.log("Login failed = ", JSON.stringify(error));
        }
      );
    }
  };

  const renderBoxBottomSection = (notifications) => {
    return (
      <View style={{ paddingTop: 8 }}>
        <FlatList
          data={notifications}
          renderItem={({ item, index }) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: 5,
                }}
                key={index}
              >
                <View style={{ paddingRight: 5 }}>
                  <View
                    style={{
                      width: 7,
                      height: 7,
                      backgroundColor: "#FF2F2F",
                      borderRadius: 7,
                    }}
                  />
                </View>

                <View style={{ paddingRight: 5 }}>
                  <PrestoText
                    color={theme.black}
                    size={11}
                    fontStyle={"400.normal"}
                  >
                    {item.title}
                  </PrestoText>
                </View>

                <View style={{}}>
                  <PrestoText
                    color={theme.black}
                    size={11}
                    fontStyle={"700.bold"}
                  >
                    {item.text1}
                  </PrestoText>
                </View>
              </View>
            );
          }}
        />
      </View>
    );
  };

  const renderBox = (data, index, containerStyles = {}) => {
    let shadowStyles = {};

    let isEnabled =
      hasPermissionToAccess(data.state)
        && !isWeb ? networkInfo?.isConnected : true;
    if (user.role !== "OUTLET_AGENT" && data.state === "admin_management") {
      isEnabled = false;
    }

    if (
      hasPermissionToAccess(data.state) &&
      (data.state === "pos_management" ||
        data.state == "purchase_request_management")
    ) {
      isEnabled = true;
    }

    let notifications = [];
    if (
      data.state === "pos_management" &&
      !_.isEmpty(featureActions?.pos_management)
    ) {
      let openCartCount = _.get(featureActions, "pos_management.openCarts");
      if (openCartCount > 0) {
        notifications.push({
          title: I18n.t("screen_messages.cart.open_carts"),
          text1: openCartCount,
        });
      }
    }

    if (Platform.OS === "ios") {
      shadowStyles = {
        shadowColor: "rgba(98, 98, 98, 0.5)",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 20,
      };
    } else if (Platform.OS === "android") {
      shadowStyles = {
        elevation: 20,
      };
    }

    return (
      <Pressable
        key={index}
        style={{
          backgroundColor: theme.white,
          borderRadius: 12,
          width: 150,
          height: 150,
          position: "relative",
          opacity: isEnabled ? 1 : 0.4,
          ...shadowStyles,
          ...containerStyles,
        }}
        onPress={() => isEnabled && onPress(data)}
      >
        {notifications.length ? (
          <View
            style={{
              position: "absolute",
              top: -18,
              left: 150 - 18,
              zIndex: 2,
            }}
          >
            <View
              style={{
                backgroundColor: "red",
                width: 36,
                height: 36,
                borderRadius: 36,
              }}
            >
              <PrestoText
                color={theme.white}
                size={18}
                fontStyle={"600.semibold"}
                extraStyle={{
                  textAlign: "center",
                  textTransform: "capitalize",
                  padding: 5,
                }}
              >
                {notifications[0].text1}
              </PrestoText>
            </View>
          </View>
        ) : null}

        <View
          style={{
            backgroundColor: theme.white,
            flex: 1,
            borderRadius: 12,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View>
            <PrestoIcon icon={data.icon} />
          </View>

          <View style={{ paddingTop: theme.primaryPadding }}>
            <PrestoText
              color={theme.primary}
              size={18}
              fontStyle={"600.semibold"}
              extraStyle={{ textAlign: "center", textTransform: "capitalize" }}
            >
              {data.title}
            </PrestoText>
          </View>
        </View>
      </Pressable>
    );
  };

  const renderBox1 = (data, index, containerStyles = {}) => {
    let isEnabled =
      hasPermissionToAccess(data.state)
        && !isWeb ? networkInfo.isConnected : true;
    if (user.role !== "OUTLET_AGENT" && data.state === "admin_management") {
      isEnabled = false;
    }

    if (hasPermissionToAccess(data.state) && data.state === "pos_management") {
      isEnabled = true;
    }

    let notifications = [];
    if (
      data.state === "pos_management" &&
      !_.isEmpty(featureActions?.pos_management)
    ) {
      let openCartCount = _.get(featureActions, "pos_management.openCarts");
      if (openCartCount > 0) {
        notifications.push({
          title: I18n.t("screen_messages.cart.open_carts"),
          text1: openCartCount,
        });
      }
    }
    return (
      <Pressable
        key={index}
        onPress={() => isEnabled && onPress(data)}
        style={{
          paddingBottom: 5,
          backgroundColor: theme.white,
          borderRadius: 12,
          flex: 1,
          marginBottom: theme.containerPadding,
          ...containerStyles,
          opacity: isEnabled ? 1 : 0.4,
        }}
      >
        <View>
          <View
            style={{
              backgroundColor: data.bgColor || "#6C6C6C",
              paddingHorizontal: 16,
              paddingTop: 18,
              paddingBottom: 13,
              borderTopRightRadius: 12,
              borderTopLeftRadius: 12,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <PrestoIcon icon={data.icon} />
            </View>

            <View style={{ paddingLeft: theme.primaryPadding }}>
              <PrestoText color={theme.white} size={18} fontStyle={"700.bold"}>
                {data.title}
              </PrestoText>
            </View>
          </View>

          <View
            style={{
              paddingHorizontal: 16,
              height: 40,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {notifications.length
              ? renderBoxBottomSection(notifications)
              : null}
            {notifications.length ? (
              <View>
                <PrestoIcon
                  icon={
                    <svgs.RightIcon
                      strokeWidth={3}
                      width={12}
                      height={12}
                      color={theme.black}
                      primaryColor={theme.black}
                    />
                  }
                />
              </View>
            ) : null}
          </View>
        </View>
      </Pressable>
    );
  };

  useEffect(() => {
    // if (!isWeb) {
    //   let obj = new DBActivityManager();
    //   console.log(obj, "fkljsaijfiojsifjojsoidf")
    // }
    // obj.addActivity({})
  }, []);

  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1 }}
      loading={loading}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <View
          style={{
            width: (isMobile || isMobileWeb) ? "25%" : "20%",
          }}
        >
          <PrestoText
            color={theme.white}
            size={(isMobile || isMobileWeb) ? 13 : 20}
            fontStyle={"600.semibold"}
            extraStyle={{ paddingVertical: 30, paddingLeft: 20 }}
          >
            {user.name}
          </PrestoText>
        </View>
        <View style={{}}>
          <Image
            source={Images.logo_transparent}
            style={{
              width: (isMobile || isMobileWeb) ? 180 : 378,
              marginTop: 30,
            }}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            width: (isMobile || isMobileWeb) ? "25%" : "20%",
            // position: "absolute",
            // zIndex: 2,
            // right: 0,
            // top: isMobile ? 20 : 0,
          }}
        >
          <Pressable onPress={() => onLogout()} style={{ paddingLeft: 15 }}>
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                paddingTop: 35,
                paddingRight: 35,
              }}
            >
              <PrestoIcon
                icon={
                  <svgs.LogoutIcon
                    strokeWidth={3}
                    width={32}
                    height={32}
                    color={theme.white}
                  />
                }
              />
              <PrestoText
                color={theme.white}
                size={10}
                fontStyle={"600.semibold"}
                extraStyle={{ paddingTop: 3 }}
              >
                {I18n.t("screen_messages.logout")}
              </PrestoText>
            </View>
          </Pressable>
        </View>
      </View>
      {!isWeb ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 2,
            right: 0,
            top: (isMobile || isMobileWeb) ? 60 : 80,
          }}
        >
          <Pressable onPress={() => resync()} style={{ paddingLeft: 15 }}>
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                paddingTop: 35,
                paddingRight: 35,
              }}
            >
              <PrestoIcon
                icon={
                  <svgs.RefreshIcon
                    strokeWidth={3}
                    width={32}
                    height={32}
                    color={theme.white}
                  />
                }
              />
              <PrestoText
                color={theme.white}
                size={10}
                fontStyle={"600.semibold"}
                extraStyle={{ paddingTop: 3 }}
              >
                {I18n.t("screen_messages.resync")}
              </PrestoText>
            </View>
          </Pressable>
        </View>
      ) : (
        <></>
      )}
      <ScrollView
        ref={scrollRef}
        style={{ backgroundColor: theme.primary, flex: 1 }}
        contentContainerStyle={{
          paddingTop: isDesktop || (isMobile || isMobileWeb) ? 50 : 20,
          minHeight: windowHeight,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirextion: "column",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: padding,
            // paddingHorizontal: "18%",
          }}
        >
          <FullWidthHorizontalPaddedBox>
            <View style={{ marginBottom: isMobile ? 20 : 70 }}>
              <Image
                source={Images.logo_transparent}
                style={{ width: 378, height: 140, alignSelf: "center" }}
              />
            </View>
            <FullWidthHorizontalPaddedBox>
              {_.map(
                _.chunk(getPosFeatureData(), maxColumns),
                (row, mainIndex) => {
                  const hasMaxItems = row.length === maxColumns;
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent:
                          isMobile || isMobileWeb ? "space-between" : "center",
                        alignItems: "center",
                        marginBottom: (isMobile || isMobileWeb) ? 14 : 56,
                      }}
                    >
                      {_.map(row, (column, index) => {
                        const isLast = row.length - 1 === index;
                        let containerStyles = {
                          marginRight: isDesktop ? 68 : 0,
                        };

                        if (isLast) {
                          containerStyles = {};
                        }

                        if (!isDesktop) {
                          containerStyles.width = "100%";
                        }

                        if (isMobile || isMobileWeb) {
                          containerStyles.width = "45%";
                          containerStyles.marginBottom = 20;
                        }

                        return renderBox(column, index, containerStyles);
                      })}
                    </View>
                  );
                }
              )}
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 14,
                }}
              >
                <PrestoText
                  color={theme.white}
                  size={16}
                  fontStyle={"600.semibold"}
                >
                  {I18n.t("screen_messages.choose_a_language")}
                </PrestoText>
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <PrestoText
                  color={theme.white}
                  size={16}
                  fontStyle={"600.semibold"}
                >
                  {I18n.t("screen_messages.language.portugese")}
                </PrestoText>
                <View style={{ marginHorizontal: 6 }}>
                  <Switch
                    trackColor={{
                      false: theme.white,
                      true: theme.white,
                    }}
                    thumbColor={theme.gray}
                    ios_backgroundColor={theme.white}
                    onValueChange={toggleNetwork}
                    value={language === "en-US"}
                  />
                </View>
                <PrestoText
                  color={theme.white}
                  size={16}
                  fontStyle={"600.semibold"}
                >
                  {I18n.t("screen_messages.language.english")}
                </PrestoText>
              </View>
              <VerticalSpacing size={15} />
            </FullWidthHorizontalPaddedBox>
          </FullWidthHorizontalPaddedBox>
        </View>
        <View></View>
      </ScrollView>
    </LoadingContainer>
  );
}

function getStyles(theme, windowHeight) {
  return {
    outerContainer: {
      borderColor: theme.white,
      borderWidth: 2,
      borderRadius: 12,
      marginRight: 20,
      marginBottom: 20,
      height: (24 / 100) * windowHeight,
      width: 180,
    },
    container: {
      borderTopWidth: 2,
      borderTopColor: theme.white,
      borderBottomWidth: 2,
      borderBottomColor: theme.white,
      height: "20%",
      width: "100%",
      justifyContent: "center",
    },
    innerContainer: {
      paddingTop: 10,
    },
  };
}
