import React, { useContext } from "react";
import { View, FlatList, Pressable } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";
import utils from "../../utils";
import OrderModel from "@presto-component-models/OrderModel";
import CouponCard from "@presto-cards/OffersCard/CouponCard";

export default function CouponTable({
  items,
  onEndReached,
  activeRow,
  onPressRow,
  onDownloadReport,
}) {
  const { theme } = useContext(ThemeContext);
  const isDesktop = utils.isDesktop();
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();
  const tableColumns = [
    {
      title: I18n.t("screen_messages.coupons.coupon_details"),
      width: "20%",
      thType: "highlighted",
    },
    {
      title: _.capitalize(I18n.t("screen_messages.purchase_requests.type")),
      width: "10%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: _.capitalize(I18n.t("screen_messages.coupons.value_type_title")),
      width: "20%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: _.capitalize(I18n.t("screen_messages.common.value")),
      width: "20%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.reports.date_range"),
      width: "30%",
      viewStyles: {
        alignItems: "center",
        paddingRight: 10,
      },
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            ...column.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 10 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 13,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ...column.viewStyles,
            };
            textProps = {
              extraStyle: { paddingLeft: 10 },
              color: theme.black,
              fontStyle: "400.bold",
              size: 16,
            };
          }

          if (column.title == "Amount") {
            viewStyles = {
              ...viewStyles,
              alignItems: "center",
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText {...textProps}>{column.title}</PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    let code = item.code;
    let title = item.title;
    let type = item.type;
    let valueType = _.capitalize(_.replace(item.value_type, "_", " "));
    let startTime = moment(item.start_time);
    let endTime = moment(item.end_time);
    let dateRange = `${startTime.format("DD-MM-YYYY")} - ${endTime.format(
      "DD-MM-YYYY"
    )}`;
    let value =
      item.value_type === "fixed_discount" ? item.value / 100 : item.value;
    let maxDiscount = item.max_discount / 100;

    const isActive = _.get(activeRow, "id") === item.id;
    const bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;

    if (isMobile || isMobileWeb) {
      return (
        <CouponCard
          item={item}
          tehme={theme}
          bgColor={bgColor}
          dateRange={dateRange}
          onPressRow={onPressRow}
          textColor1={textColor1}
          textColor2={textColor2}
        />
      );
    }

    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          height: 60,
          backgroundColor: bgColor,
          borderRadius: 15,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "20%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor1}
            fontStyle={"600.semibold"}
          >
            {code}
          </PrestoText>

          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {title}
          </PrestoText>
        </View>

        <View
          style={{
            width: `10%`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {type}
          </PrestoText>
        </View>

        <View
          style={{
            width: `20%`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {valueType}
          </PrestoText>
        </View>

        <View
          style={{
            width: `20%`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {value}
          </PrestoText>
        </View>

        <View
          style={{
            width: `30%`,
            flexDirection: "column",
            marginRight: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{
              textAlign: "center",
            }}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {dateRange}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  return (
    <View>
      <FlatList
        stickyHeaderIndices={[0]}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={items}
        renderItem={renderItem}
        ListHeaderComponent={() => {
          if (isMobile || isMobileWeb) return;
          return <RenderItemHeader />;
        }}
        onEndReached={onEndReached}
      />
    </View>
  );
}
